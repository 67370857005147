import { Language, Lightning, Router } from '@lightningjs/sdk'

import List from '../components/List'
import SettingsTile from '../components/items/SettingsTile'

import { COLORS, SCREEN_SIZE } from '../constants'
import { RouterPage } from '../../types/global'

export default class Settings extends Lightning.Component<
  Lightning.Component.TemplateSpecLoose,
  RouterPage
> {
  _list: any

  static override _template() {
    return {
      w: SCREEN_SIZE.width,
      h: SCREEN_SIZE.height,
      List: {},
    }
  }

  override _init() {
    const items = this._createItems()
    this.patch({ List: items })
    this.stage.setClearColor(COLORS.dark)
    this.stage.gc()
    this._list = this.tag('List')
  }

  override _active() {
    this._setState('List')
    const currentIndex = this._list.index
    this._list.items = this._getItemsList()
    this._list.setIndex(currentIndex)
  }

  _createItems() {
    return this.stage.c({
      type: List,
      x: 80,
      y: 117,
      tag: 'settingsTile',
      items: this._getItemsList(),
    })
  }

  _getItemsList() {
    return [
      {
        itemType: SettingsTile,
        item: {
          title: Language.translate('my_profile'),
          description: Language.translate('manage_your_profile'),
          icon: 'images/settings/key-2x.png',
          page: 'myprofile',
        },
      },
      {
        itemType: SettingsTile,
        item: {
          title: Language.translate('do_not_sell_my_personal_information'),
          description: Language.translate('privacy_choices_info'),
          icon: 'images/settings/privacy_shield.png',
          page: 'privacy',
        },
      },
      {
        itemType: SettingsTile,
        item: {
          title: Language.translate('about'),
          description: Language.translate('about_info'),
          icon: 'images/settings/about.png',
          page: 'about',
        },
      },
      {
        itemType: SettingsTile,
        item: {
          title: Language.translate('app_language'),
          description: Language.translate('app_language_info'),
          icon: 'images/settings/language.png',
          page: 'applanguage',
        },
      },
      {
        itemType: SettingsTile,
        item: {
          title: Language.translate('faq'),
          description: Language.translate('faq_info'),
          icon: 'images/settings/faq.png',
          page: 'faq',
        },
      },
      {
        itemType: SettingsTile,
        item: {
          title: Language.translate('contact_us'),
          description: Language.translate('contact_us_info'),
          icon: 'images/settings/contact_us.png',
          page: 'contactus',
        },
      },
    ]
  }

  showSubPage() {
    const item = this.tag('List').activeItemWrapper.item
    const routeParams =
      item.page === 'applanguage' ? { keepAlive: true, reuseInstance: true } : { keepAlive: true }

    Router.navigate(`settings/${item.page}`, routeParams, item.page === 'faq')
  }

  override _handleBack(e: Event) {
    e.preventDefault()
    performance.mark('startRouting')
    this.widgets.menu.focusProfileItem()
    Router.focusWidget('Menu')
  }

  static override _states() {
    return [
      class List extends this {
        override _getFocused() {
          return this.tag('List') || this
        }

        override _handleUp() {
          this.widgets.menu.focusProfileItem()
          Router.focusWidget('Menu')
        }

        override _handleEnter() {
          this.showSubPage()
        }
      },
    ]
  }
}
