import { Language, Lightning, Registry, Router, Utils } from '@lightningjs/sdk'
import { Subscription } from 'rxjs'

import TextButton from '../../components/buttons/TextButton'

import { FastImg, pipeSeparate, setSmooth } from '../../helpers'
import { getUserProfile } from '../../api/Identity'
import {
  ALIGN_ITEMS,
  COLORS,
  FLEX_DIRECTION,
  FONT_FACE,
  JUSTIFY_CONTENT,
  OLY_IMPOLICY,
  ROUTE,
  ROUTE_ENTITY,
  SCREEN_SIZE,
  TEXT_ALIGN,
} from '../../constants'
import RouterUtil from '../../util/RouterUtil'
import { reduceEpisodeCount } from '../../api/Api'
import { ACTIVATION_LANDING, ACTIVATION_TYPE } from '../../widgets/Modals/activation/constants'
import { RouterPage } from '../../../types/global'
import ModalManager, { ModalTypes } from '../../lib/ModalManager'
import { AuthenticationEvents } from '../../authentication/Authentication'
import { useRequest } from '../../lib/useRequest'
import { CreditsPageRequestConfig } from './request'

export default class Credits extends Lightning.Component<
  Lightning.Component.TemplateSpecLoose,
  RouterPage
> {
  _userProfile: any
  _videoId: any
  _authnSubscription: Subscription

  static override _template() {
    return {
      rect: true,
      w: SCREEN_SIZE.width,
      h: SCREEN_SIZE.height,
      color: COLORS.black,
      Image: {
        x: 275,
        y: 225,
        w: 790,
        h: 444,
      },
      Badge: {
        x: 275,
        y: 225,
        h: 50,
        rect: true,
        color: COLORS.white1,
        flex: {},
        Label: {
          flexItem: {
            marginTop: 10,
            marginBottom: -4,
            marginLeft: 20,
            marginRight: 20,
          },
          color: COLORS.dark,
          text: {
            textAlign: TEXT_ALIGN.center,
            fontSize: 24,
            fontFace: FONT_FACE.regular,
          },
        },
      },
      KeyImage: {
        x: 985,
        y: 225,
        w: 80,
        h: 50,
        rect: true,
        color: COLORS.black4,
        alpha: 1,
        ImageLabel: {
          x: (w: any) => w / 2,
          y: (h: any) => h / 2,
          mount: 0.5,
          w: 40,
          h: 21,
          color: COLORS.white1,
          texture: Lightning.Tools.getSvgTexture(Utils.asset('images/key.svg'), 40, 21),
        },
      },
      Title: {
        x: 275,
        y: 695,
        w: 730,
        h: 50,
        alpha: 0.9,
        text: {
          fontSize: 44,
          fontFace: FONT_FACE.regular,
          textColor: COLORS.white,
        },
      },
      SecondaryTitle: {
        x: 275,
        y: 754,
        w: 730,
        h: 38,
        alpha: 0.7,
        text: {
          fontSize: 28,
          fontFace: FONT_FACE.regular,
          textColor: COLORS.white,
        },
      },
      LogoContainer: {
        x: 1005,
        y: 700,
        h: 50,
        w: 53,
        rect: true,
        color: COLORS.transparent,
        flex: {
          alignItems: ALIGN_ITEMS.center,
          justifyContent: JUSTIFY_CONTENT.center,
        },
        Logo: {
          rect: true,
        },
      },
      ActionContainer: {
        x: 1165,
        y: 321,
        flex: {
          direction: FLEX_DIRECTION.column,
          justifyContent: JUSTIFY_CONTENT.center,
          alignItems: ALIGN_ITEMS.center,
          paddingLeft: 29,
          paddingRight: 29,
        },
        CreditsTitle: {
          flexItem: {
            marginBottom: 3,
          },
          text: {
            fontSize: 46,
            fontFace: FONT_FACE.light,
            textColor: COLORS.white,
            lineHeight: 60,
            wordWrapWidth: 614,
          },
        },
        CreditsContainer: {
          flex: {
            direction: FLEX_DIRECTION.row,
            justifyContent: JUSTIFY_CONTENT.spaceBetween,
            alignItems: ALIGN_ITEMS.center,
          },
          flexItem: {
            marginBottom: 38,
          },
          CreditNum: {
            rect: true,
            w: 60,
            h: 60,
            color: COLORS.white,
            flex: {
              direction: FLEX_DIRECTION.row,
              justifyContent: JUSTIFY_CONTENT.center,
              alignItems: ALIGN_ITEMS.center,
            },
            flexItem: {
              marginRight: 9,
            },
            NumLabel: {
              flexItem: {
                marginTop: 4,
              },
              text: {
                fontSize: 52,
                textAlign: TEXT_ALIGN.center,
                fontFace: FONT_FACE.regular,
                textColor: COLORS.black,
              },
            },
            shader: {
              type: Lightning.shaders.RoundedRectangle,
              radius: 30,
            }
          },
          CreditMessage: {
            flexItem: {
              marginTop: 8,
            },
            text: {
              fontSize: 48,
              lineHeight: 52,
              fontFace: FONT_FACE.regular,
              textColor: COLORS.white,
            },
          },
        },
        WatchNowBtn: {
          flexItem: {
            marginBottom: 16,
          },
          w: 420,
          h: 50,
          type: TextButton,
          radius: 22,
          fontSize: 26,
          fontFace: FONT_FACE.regular,
          focusFontColor: COLORS.dark,
          unfocusFontColor: COLORS.white,
          focusBackGroundColor: COLORS.lightGray3,
          unfocusBackgroundColor: COLORS.black3,
          padding: 0,
          strokeWidth: 2,
          strokeColor: COLORS.white,
          unfocusBgAlpha: 0.6,
          unfocusLabelAlpha: 0.7,
        },
        LaterButton: {
          w: 420,
          h: 50,
          type: TextButton,
          radius: 22,
          fontSize: 26,
          fontFace: FONT_FACE.regular,
          focusFontColor: COLORS.dark,
          unfocusFontColor: COLORS.white,
          focusBackGroundColor: COLORS.lightGray3,
          unfocusBackgroundColor: COLORS.black3,
          padding: 0,
          strokeWidth: 2,
          strokeColor: COLORS.white,
          unfocusBgAlpha: 0.6,
          unfocusLabelAlpha: 0.7,
        },
      }
    }
  }

  override set params(params: any) {
    this._videoId = params.videoId
  }

  override _init() {
    this.stage.setClearColor(COLORS.dark)
    if (ModalManager.getActiveModal()) ModalManager.close()
    this._authnSubscription = AuthenticationEvents.subscribe((type: ACTIVATION_TYPE) => {
      if (type === ACTIVATION_TYPE.MVPD) this._tvProviderLinked()
    })
  }

  override _detach() {
    this._authnSubscription.unsubscribe()
  }

  async load(): Promise<void> {
    this.apiData = await useRequest(CreditsPageRequestConfig(this._videoId)).fetch()
  }

  set apiData(v: any) {
    Registry.setTimeout(() => {
      setSmooth(this.widgets.loader, 'visible', 0)
      this.videoData = v.metadata
      this._userProfile = getUserProfile()
      this._setUserProfile()
      this._setState('WatchNowBtn')
    }, 100)
  }

  set videoData(data: any) {
    if (!data) return
    let title, secondaryTitle
    if (data.programmingType === 'Movie') {
      title = data.title
      secondaryTitle = pipeSeparate([data.rating, data.secondaryTitle])
    } else {
      title = data.secondaryTitle
      secondaryTitle = data.title
    }
    this.patch({
      Image: {
        texture: FastImg(data.image).contain(790, 444),
      },
      Badge: {
        alpha: data.labelBadge ? 1 : 0,
        Label: {
          text: { text: data.labelBadge },
        },
      },
      KeyImage: {
        alpha: data.locked,
      },
      Title: {
        text: { text: title },
      },
      SecondaryTitle: {
        text: { text: secondaryTitle },
      },
      LogoContainer: {
        Logo: {
          texture: FastImg(data.whiteBrandLogo).contain(53, 50, OLY_IMPOLICY.SQUARE),
        },
      },
    })
  }

  _setUserProfile() {
    const episodeCount = this._userProfile.episodeCount
    this.tag('CreditsTitle').patch({
      text: {
        text: episodeCount
          ? Language.translate('ready_to_watch')
          : Language.translate('link_tv_provider_to_watch'),
      },
    })
    this.tag('CreditNum').patch({
      alpha: episodeCount,
    })
    this.tag('CreditNum.NumLabel').patch({
      text: { text: episodeCount },
    })
    this.tag('CreditMessage').patch({
      text: {
        text: episodeCount
          ? Language.translate('credits_Available')
          : Language.translate('no_credits'),
      },
    })
    this.tag('WatchNowBtn').patch({
      label: (episodeCount
        ? Language.translate('watch_now')
        : Language.translate('link_tv_provider')
      ).toUpperCase(),
    })
    this.tag('LaterButton').patch({
      label: (episodeCount
        ? Language.translate('maybe-later')
        : Language.translate('cancel')
      ).toUpperCase(),
    })
  }

  _tvProviderLinked() {
    if (!this._userProfile.episodeCount)
      RouterUtil.navigateToRoute(ROUTE.video, {
        entity: ROUTE_ENTITY.videoId,
        value: this._videoId,
      })
  }

  static override _states() {
    return [
      class WatchNowBtn extends this {
        override _userProfile: any
        override _videoId: any

        override _getFocused() {
          return this.tag('WatchNowBtn') || this
        }

        override _handleDown() {
          this._setState('LaterButton')
        }

        override _handleEnter() {
          if (this._userProfile.episodeCount) {
            reduceEpisodeCount(this._videoId)
            RouterUtil.navigateToRoute(
              ROUTE.video,
              {
                entity: ROUTE_ENTITY.videoId,
                value: this._videoId,
              },
              { allowToPlay: true }
            )
          } else {
            ModalManager.open(ModalTypes.ACTIVATION, {
              landing: ACTIVATION_LANDING.MVPD,
              videoId: this._videoId,
            })
          }
        }
      },
      class LaterButton extends this {
        override _getFocused() {
          return this.tag('LaterButton') || this
        }

        override _handleUp() {
          this._setState('WatchNowBtn')
        }

        override _handleEnter() {
          Router.back()
        }
      },
    ]
  }
}
