import { checkCreditsFlow } from '../../../../helpers'
import { missingDataError, StreamLoaderErrors } from '../error'
import { StreamRequest } from '../request'
import { Program } from '../../../../store/PlayerStore/PlayerStore'
import { SingleStream } from '../../../../store/PlayerStore/actions'

export const creditsFlow = (bypass: boolean) => async (
  request: StreamRequest,
  stream: SingleStream,
  program: Program,
) => {
  try {
    if (!stream) throw missingDataError('Stream is undefined')
    const recheck = await checkCreditsFlow(stream, program, bypass)
    /**
     * If a user is logged in with the profile,
     * we need to make sure they have enough credits to watch content.
     * Well, let's double-check that.
     */
    if (recheck) await checkCreditsFlow(stream, program, bypass)
    return request
  } catch (e) {
    request.error = {
      handled: true,
      detail: StreamLoaderErrors.USER_CANCELLED,
      data: e,
    }
    throw request
  }
}
