import { Storage, Router, Log } from '@lightningjs/sdk'
import { DrmType, SessionController, StreamingProtocol } from '@sky-uk-ott/core-video-sdk-js'

import AppConfigFactorySingleton from '../../config/AppConfigFactory'

import { generateDeviceID, getDeviceId, storeDeviceId } from '../DeviceIdUtils'
import { fontSpecs, computeFontSource } from './util/fonts'
import { DebugControllerSingleton } from '../../util/debug/DebugController'
import {
  CCColors,
  CCEdgeStyles,
  CCFontSizes,
  CCFonts,
  CCOpacities,
} from '../ClosedCaptions/ClosedCaptionsUtils'

import { getRenderPrecision, isSamsung, isVizio, isXbox } from '../../helpers'

import { Keys, STORAGE_KEYS, USER_OPT_OUT_PREFERENCE, ROUTE, PLAYER_SIZE } from '../../constants'

import { APP_IDENTIFIER, ErrorType, IKeyMap, IStageSettings, LEMONADE_PLATFORM } from './types'
import { SupportedPlatforms } from '../../graphql/generated/types'
import BaseAnnounce from '../tts/Announces/BaseAnnounce'
import Announce from '../tts/Announces/Announce'
import { logError as datadogLogError } from '../Datadog'
export const TV_PLATFORM_TAG = 'TV Platform'

export enum PlatformSubscriptionType {
  VOICE = 'voiceGuidance',
  VOICE_CONTROL = 'voiceControl',
  CC = 'closedCaptions',
  BACKGROUND = 'background',
  FOREGROUND = 'foreground',
}

/**
 * Generic subscription wrapper since platforms
 * differ on how they clear the subscriptions
 * @class
 */
export class SubscriptionWrapper {
  unsubscribe() {}
}

export type CCStyleEvent = {
  fontStyle: CCFonts
  fontSize: CCFontSizes
  fontColor: CCColors
  edgeStyle: CCEdgeStyles
  edgeColor: CCColors
  edgeOpacity: CCOpacities
  fontOpacity: CCOpacities
  backgroundColor: CCColors
  backgroundOpacity: CCOpacities
  windowColor: CCColors
  windowOpacity: CCOpacities
}

export type NavigationEvent = {
  route: ROUTE
  entity?: {
    entityType: string
    value: string
  }
}

type Capabilities = {
  externalAppLinking: boolean
  concurrentStreams: boolean
}

export default class BasePlatform {
  _deviceType: string
  _platformName!: string
  _lemonadePlatform!: LEMONADE_PLATFORM
  _bffPlatform!: SupportedPlatforms
  _streamingProtocol!: StreamingProtocol
  _appIdentifier!: APP_IDENTIFIER
  _subscriptions: SubscriptionWrapper[] = []
  _advertiserId!: string
  _deviceAdvertisingIdType!: string
  _deviceSdkConfig = {}
  _drmType = DrmType.Widevine

  get deviceId(): string {
    return getDeviceId()
  }

  set deviceId(deviceId: string) {
    storeDeviceId(deviceId)
  }

  get deviceType(): string {
    return this._deviceType
  }

  get devicePartnerId(): string {
    return (window as any).DEVICE_TYPE || ''
  }

  get drmType() {
    return this._drmType
  }

  get deviceSdkConfig() {
    return this._deviceSdkConfig
  }

  get lemonadePlatform(): LEMONADE_PLATFORM {
    return this._lemonadePlatform
  }

  get bffPlatform(): SupportedPlatforms {
    return this._bffPlatform
  }

  get appIdentifier(): APP_IDENTIFIER {
    return this._appIdentifier
  }

  get platformName() {
    return this._platformName
  }

  get streamingProtocol(): StreamingProtocol {
    return this._streamingProtocol
  }

  get capabilities(): Capabilities {
    return {
      externalAppLinking: false,
      // Setting true as a default, but this needs to be tested on each device
      // and add the override for platforms that don't support concurrent streams
      concurrentStreams: true,
    }
  }

  get deviceInfo() {
    return {
      primaryHardwareType: 'Unknown', //#restricted
      model: 'None', //#mandatory
      version: 'None',
      manufacturer: 'None',
      vendor: 'None',
      osName: 'Linux', //#mandatory, #restricted
      osFamily: 'Linux', // #restricted
      osVendor: 'None',
      osVersion: 'None',
      browserName: 'Symbian Browser', //#restricted
      browserVendor: 'Netscape', //#restricted
      browserVersion: 'None',
      userAgent: 'None',
      displayWidth: 0,
      displayHeight: 0,
      displayPpi: 0,
      diagonalScreeenSize: 0,
      connectionIp: 'None',
      connectionPort: 0,
      connectionType: 'None',
      connectionSecure: false, //#restricted
      applicationId: 'None',
    }
  }

  getAllowVSFError(): boolean {
    return false
  }

  platformFlags = Object.freeze({
    // on some remotes, there is only one 'back' button and no 'return' button
    hideReturnToTopHint: isXbox() || isSamsung(),
    shouldToggleRackOnBackspace: isXbox(),
    showPreLoading: isXbox(),
    useHttpForLogoURL: isSamsung(),
    singleThreadVideoBehavior: isVizio(),
    useHashRouter: isSamsung(),
  })

  async init(): Promise<void> {
    await this.generateDeviceId()
  }

  public tts(): { cancel(): void; speak(toSpeak: string, notification?: boolean): Announce } {
    return {
      speak(toSpeak, notification = false) {
        return new BaseAnnounce(toSpeak, notification)
      },
      cancel() {
        window.speechSynthesis.cancel()
      },
    }
  }

  /**
   * Implementation required
   */
  exit() {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe())
    this._subscriptions = []
  }

  dismissLoadingScreen() {}

  getStageSettings(): IStageSettings {
    return {
      devicePixelRatio: window.devicePixelRatio || 1,
    }
  }

  scaleVideoProperty(val: number, type: PLAYER_SIZE): number {
    return type === PLAYER_SIZE.FULL ? Math.round(val * getRenderPrecision()) : val
  }

  getNumbersKeyMapping() {
    return {
      48: '0',
      49: '1',
      50: '2',
      51: '3',
      52: '4',
      53: '5',
      54: '6',
      55: '7',
      56: '8',
      57: '9',
    }
  }

  getAtoZAndNumberKeyMapping(numbersKeyMapping = false) {
    const numbers = numbersKeyMapping ? this.getNumbersKeyMapping() : {}
    return {
      ...numbers,
      65: 'A',
      66: 'B',
      67: 'C',
      68: 'D',
      69: 'E',
      70: 'F',
      71: 'G',
      72: 'H',
      73: 'I',
      74: 'J',
      75: 'K',
      76: 'L',
      77: 'M',
      78: 'N',
      79: 'O',
      80: 'P',
      81: 'Q',
      82: 'R',
      83: 'S',
      84: 'T',
      85: 'U',
      86: 'V',
      87: 'W',
      88: 'X',
      89: 'Y',
      90: 'Z',
    }
  }

  getPlatformKeyMapping(): IKeyMap {
    return {
      36: Keys.RETURN,
      38: Keys.ARROW_UP,
      40: Keys.ARROW_DOWN,
      37: Keys.ARROW_LEFT,
      39: Keys.ARROW_RIGHT,
    }
  }

  async generateDeviceId(): Promise<void> {
    await generateDeviceID()
  }

  protected getStorageBasedOptOut(): USER_OPT_OUT_PREFERENCE {
    const userOptOutFromStorage = Number(Storage.get(STORAGE_KEYS.USER_OPT_OUT))
    return userOptOutFromStorage === USER_OPT_OUT_PREFERENCE.DISALLOW_SALE ||
      DebugControllerSingleton.userOptOut
      ? USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
      : USER_OPT_OUT_PREFERENCE.ALLOW_SALE
  }

  getUserOptOut(): USER_OPT_OUT_PREFERENCE {
    return this.getStorageBasedOptOut()
  }

  getAdvertiserId(): string {
    return (
      this._advertiserId ||
      AppConfigFactorySingleton.config?.core_video_sdk?.deviceAdvertisingId ||
      ''
    )
  }

  getDeviceAdvertisingIdType(): string {
    return (
      this._deviceAdvertisingIdType ||
      AppConfigFactorySingleton.config?.core_video_sdk?.deviceAdvertisingIdType ||
      ''
    )
  }

  getAdvertiserDeviceType() {
    return 'dpid'
  }

  async getModelNumber(): Promise<string> {
    return 'None'
  }

  getFirmware() {
    return 'None'
  }

  handleDeepLink() {
    console.warn('You must implement this method!')
  }

  checkDeepLinkNavigation = () => {}

  setPreview = async (): Promise<void> => {}

  isExitToPeacockSupported(): boolean {
    return false
  }

  exitToPeacock() {
    Router.navigate(ROUTE.home)
  }

  /**
   * Initialize fonts for the platform
   */
  initializeFonts = () => {
    const fontLoadingPromises = fontSpecs.map((spec) => {
      if (spec.src) {
        const fontFace = new FontFace(spec.family, computeFontSource(spec), spec.descriptors)
        ;(document.fonts as any).add(fontFace)
        return fontFace.load()
      }
      return null
    })
    return Promise.all(fontLoadingPromises)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  subscribe = (
    evt: PlatformSubscriptionType,
    callback: () => void
  ): SubscriptionWrapper | undefined => {
    return undefined
  }

  // force garbage collection where referenced
  getForceGC() {
    return true
  }

  // adjustment originally added for vizio issues
  getAllowLinearGradient() {
    return true
  }

  reportError(error: { type: ErrorType; code?: string; description: string; payload?: any }) {
    // Report error via Lightning Logger
    Log.error(error)

    // Report error to Datadog service
    datadogLogError(error.description, error)
  }

  historyBack(): void {
    history.go(-1)
  }

  emulateVSFError(sessionController: SessionController | null): void {}
}
