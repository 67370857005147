import { Lightning, Utils } from '@lightningjs/sdk'
import {
  ALIGN_ITEMS,
  COLORS,
  FLEX_DIRECTION,
  FONT_FACE,
  JUSTIFY_CONTENT,
  TEXT_ALIGN,
  VERTICAL_ALIGN,
} from '../../../../constants'
import TextButton from '../../../../components/buttons/TextButton'

export default class CreditsFlowTile extends Lightning.Component {
  onClick: () => void
  static override _template(): any {
    return {
      h: 478,
      w: 596,
      flex: {
        direction: FLEX_DIRECTION.column,
        alignItems: ALIGN_ITEMS.center,
        justifyContent: JUSTIFY_CONTENT.center,
      },
      texture:
        Lightning.Tools.getRoundRect(596, 478, 20, 1, COLORS.white, true, COLORS.black),
      Title: {
        text: {
          textAlign: TEXT_ALIGN.center,
          fontSize: 37,
          fontFace: FONT_FACE.light,
          textColor: COLORS.white,
          verticalAlign: VERTICAL_ALIGN.middle,
          text: this.bindProp('title'),
          lineHeight: 48,
          wordWrap: true,
          wordWrapWidth: 510,
        },
      },
      Image: {
        h: 49,
        w: 49,
        src: this.bindProp('imgSrc', (ctx) => {
          return Utils.asset(ctx.imgSrc)
        }),
        flexItem: {
          margin: 20,
          marginBottom: 40,
        },
      },
      Button: {
        w: 420,
        h: 50,
        type: TextButton,
        radius: 22,
        fontSize: 26,
        letterSpacing: 0.8,
        fontFace: FONT_FACE.regular,
        focusFontColor: COLORS.black,
        unfocusFontColor: COLORS.white,
        focusBackGroundColor: COLORS.white,
        unfocusBackgroundColor: COLORS.transparent,
        label: this.bindProp('btnTitle', (ctx) => {
          return ctx.btnTitle.toUpperCase()
        }),
        padding: 0,
        strokeWidth: 2,
        strokeColor: COLORS.white,
        unfocusBgAlpha: 0.6,
      },
    }
  }

  override _getFocused() {
    return this.tag('Button')
  }

  override _handleEnter() {
    this.onClick()
  }
}
