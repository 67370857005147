import { Language, Lightning, Router, Utils } from '@lightningjs/sdk'

import SettingsButton from '../../components/buttons/SettingsButton'
import Section from '../../components/section'

import { getUserProfile, logoutUserProfile } from '../../api/Identity'
import { sendMetric } from '../../lib/analytics/Analytics'
import { EVENTS } from '../../lib/analytics/types'
import {
  COLORS,
  FONT_FACE,
  MyProfileStates,
  ROUTE,
  TEXT_ALIGN,
  VERTICAL_ALIGN,
} from '../../constants'
import { getMvpdLogoSrc, setSmooth } from '../../helpers'
import { ACTIVATION_LANDING, ACTIVATION_REFERRER } from '../../widgets/Modals/activation/constants'
import AuthenticationSingleton, { AuthenticationEvents } from '../../authentication/Authentication'
import { RouterPage } from '../../../types/global'
import ModalManager, {
  ModalManagerEvent,
  ModalManagerEventTypes,
  ModalTypes,
} from '../../lib/ModalManager'
import { Subscription } from 'rxjs'
import { SubscriptionBuilder, SubscriptionSources } from '../../util/SubscriptionBuilder'
import { useRouterBackDisabled } from '../../widgets/Modals/activation/hooks/useRouterBackDisabled'

export default class MyProfile extends Lightning.Component<
  Lightning.Component.TemplateSpecLoose,
  RouterPage
> {
  _authSubscription?: Subscription
  _modalSubscription?: Subscription

  static override _template() {
    return {
      x: 80,
      y: 200,
      w: 1760,
      h: 810,
      rect: true,
      color: COLORS.dark5,

      PageTitleHolder: {
        x: 100,
        y: 78,
        Icon: {
          h: 40,
          w: 40,
          src: Utils.asset('images/settings/key.png'),
        },
        Title: {
          x: 60,
          text: {
            text: Language.translate('my_profile'),
            fontSize: 30,
          },
        },
      },

      Content: {
        y: 178,
        x: 233,

        NbcProfileContent: {
          type: Section,
          announce: [Language.translate('profile'), Language.translate('get_more_text')],
          innerContent: {
            Title: {
              text: {
                text: Language.translate('nbc_profile'),
                fontSize: 40,
                fontFace: FONT_FACE.light,
              },
            },

            SubTitle: {
              y: 55,
              text: {
                text: Language.translate('get_more_text'),
                fontSize: 30,
                fontFace: FONT_FACE.light,
              },
            },

            SignInButton: {
              y: 124,
              w: 740,
              h: 70,
              type: SettingsButton,
              radius: 0,
              fontSize: 30,
              fontFace: FONT_FACE.light,
              focusFontColor: COLORS.dark,
              unfocusFontColor: COLORS.white,
              focusBackGroundColor: COLORS.lightGray3,
              unfocusBackgroundColor: COLORS.black3,
              label: Language.translate('sign_in'),
              autoWidth: false,
              padding: 0,
            },
          },
        },

        TvProviderContent: {
          y: 355,
          type: Section,
          announce: [Language.translate('tv_provider'), Language.translate('unlock_info')],
          innerContent: {
            Title: {
              text: {
                text: Language.translate('tv_provider'),
                fontSize: 40,
                fontFace: FONT_FACE.light,
              },
            },

            SubTitle: {
              y: 55,
              text: {
                text: Language.translate('unlock_info'),
                fontSize: 30,
                fontFace: FONT_FACE.light,
              },
            },

            LinkButton: {
              y: 124,
              w: 740,
              h: 70,
              type: SettingsButton,
              radius: 0,
              fontSize: 30,
              fontFace: FONT_FACE.light,
              focusFontColor: COLORS.dark,
              unfocusFontColor: COLORS.white,
              focusBackGroundColor: COLORS.lightGray3,
              unfocusBackgroundColor: COLORS.black3,
              label: Language.translate('link'),
              autoWidth: false,
              padding: 0,
            },

            MvpdLogo: {
              y: 194,
              alpha: 0.001,
            },
          },
        },
      },
    }
  }

  _disableRouterBack() {
    return true
  }

  override _init() {
    this.stage.setClearColor(COLORS.dark)
    this.tag('NbcProfileContent').announce = [
      Language.translate('profile'),
      Language.translate('get_more_text'),
    ]
    const providerContentMvpdLogo = this.tag('TvProviderContent.MvpdLogo')
    providerContentMvpdLogo.on('txLoaded', (texture: any) => {
      providerContentMvpdLogo.y -= texture.source.h
      providerContentMvpdLogo.x = this.w + this.x - texture.source.w - this.tag('Content').x - 120
      setSmooth(providerContentMvpdLogo, 'alpha', 1)
    })
    this._updateAuthUI()
    this._authSubscription = new SubscriptionBuilder()
      .with(SubscriptionSources.AUTHENTICATION)
      .subscribe(this._updateAuthUI)
  }

  _updateAuthUI = () => {
    this._setNBCUserProviderData()
    this._setTvProviderData()
  }

  override _focus() {
    this.widgets.menu.collapse({
      subCategoryItem: Language.translate('my_profile'),
    })
    this.tag('PageTitleHolder').patch({
      Title: this._setTranslatedText('my_profile'),
    })
    if (this._getState() === '') this._setState(MyProfileStates.NBCUserProfile)
  }

  override _inactive() {
    this.widgets.menu.expand()
    this.widgets.notification.visible = false
    this.widgets.confirmationdialog.visible = false
    Router.focusPage()
  }

  _setTranslatedText(transKey: any) {
    return { text: { text: Language.translate(transKey) } }
  }

  _patchTranslationByType(patchObj: any, subTitleText: any, type = 'NbcProfileContent') {
    this.tag(type).patch({
      ...patchObj,
      SubTitle: {
        ...subTitleText,
      },
    })
  }
  _setNBCUserProviderData() {
    const userProfile = getUserProfile()
    const patchObj = { Title: this._setTranslatedText('nbc_profile') }
    const getMore = this._setTranslatedText('get_more_text')
    this.tag('NbcProfileContent').announce = [
      Language.translate('profile'),
      Language.translate('get_more_text'),
    ]
    // Always do language stuff in either case.
    this._patchTranslationByType(patchObj, getMore)
    if (userProfile) {
      const userName = Language.translate('signed_user_name', userProfile.userName)
      const userCredits = Language.translate('remaining_credits', userProfile.episodeCount)
      this.tag('NbcProfileContent').patch({
        SubTitle: { alpha: 0 },
        ProfileInfo: {
          alpha: 1,
          UserName: {
            y: 55,
            text: {
              textColor: COLORS.lightGray2,
              verticalAlign: VERTICAL_ALIGN.middle,
              textAlign: TEXT_ALIGN.center,
              fontSize: 30,
              lineHeight: 35,
              fontFace: FONT_FACE.light,
              text: userName,
            },
          },
          UserCredits: {
            y: 105,
            text: {
              textColor: COLORS.lightGray2,
              verticalAlign: VERTICAL_ALIGN.middle,
              textAlign: TEXT_ALIGN.center,
              fontSize: 30,
              lineHeight: 35,
              fontFace: FONT_FACE.light,
              text: userCredits,
            },
          },
        },
        SignInButton: {
          y: 165,
          label: Language.translate('sign_out'),
        },
      })
      this.tag('NbcProfileContent').announce.pop()
      this.tag('NbcProfileContent').announce.push(userName, userCredits)
    } else {
      this.tag('NbcProfileContent').patch({
        SignInButton: {
          label: Language.translate('sign_in'),
        },
      })
      if (this.tag('NbcProfileContent.ProfileInfo')) {
        this.tag('NbcProfileContent.ProfileInfo').alpha = 0
        this.tag('NbcProfileContent').patch({
          SubTitle: {
            alpha: 1,
          },
          SignInButton: {
            y: 124,
          },
        })
      }
    }
  }

  _setTvProviderData() {
    const patchObj = { Title: this._setTranslatedText('tv_provider') }
    const unlockInfo = this._setTranslatedText('unlock_info')
    // Always do language stuff in either case.
    this._patchTranslationByType(patchObj, unlockInfo, 'TvProviderContent')
    if (AuthenticationSingleton.isAuthenticated() && !AuthenticationSingleton.isMvpdTempPass()) {
      const mvpdData = AuthenticationSingleton.getMvpdData()
      this.tag('TvProviderContent').patch({
        SubTitle: {
          alpha: 0,
        },
        TVProviderInfo: {
          y: 55,
          alpha: 1,
          TvProviderName: {
            text: {
              textColor: COLORS.lightGray2,
              verticalAlign: VERTICAL_ALIGN.middle,
              textAlign: TEXT_ALIGN.center,
              fontSize: 30,
              lineHeight: 35,
              fontFace: FONT_FACE.light,
              text: Language.translate('tv_provider_link_name', mvpdData?.mvpdDisplayName),
            },
          },
        },
        LinkButton: {
          label: Language.translate('unlink'),
        },
      })
      const mvpdLogoSrc = getMvpdLogoSrc(mvpdData?.mvpdProviderData?.activationloggedInImage_2x)
      if (mvpdLogoSrc) {
        this.tag('TvProviderContent.MvpdLogo').src = mvpdLogoSrc
      }
    } else {
      this.tag('TvProviderContent').patch({
        SubTitle: {
          alpha: 1,
        },
        TVProviderInfo: {
          alpha: 0,
        },
        LinkButton: {
          label: Language.translate('link'),
        },
        MvpdLogo: {
          alpha: 0.001,
        },
      })
      if (this.tag('TVProviderInfo')) {
        this.tag('TVProviderInfo').alpha = 0
        this.tag('TvProviderContent').patch({
          SubTitle: {
            alpha: 1,
          },
          LinkButton: {
            y: 124,
          },
        })
      }
    }
    this.widgets.menu.showHideMvpdLogo()
  }

  private _setSecondarySubscription() {
    this._modalSubscription?.unsubscribe()
    this._modalSubscription = new SubscriptionBuilder()
      .with(SubscriptionSources.MODAL)
      .subscribe((e: ModalManagerEvent) => {
        if (e.event === ModalManagerEventTypes.CLOSE) {
          if (!AuthenticationSingleton.isAuthenticated()) {
            AuthenticationEvents.pollAdobe()
          }
          this._modalSubscription?.unsubscribe()
        }
      })
  }

  override _handleBack(e: Event) {
    e.preventDefault()
    Router.navigate(ROUTE.settings)
  }

  override _detach(): void {
    this._authSubscription?.unsubscribe()
    this._modalSubscription?.unsubscribe()
    AuthenticationEvents.stopPolling()
  }

  static override _states() {
    return [
      class NBCUserProfile extends this {
        override _getFocused() {
          return this.tag('NbcProfileContent.SignInButton') || this
        }
        override _handleDown() {
          this._setState(MyProfileStates.TvProvider)
        }
        override _handleEnter() {
          const userProfile = getUserProfile()
          if (userProfile) {
            return this._setState(MyProfileStates.LogoutConfirmation, [
              { type: ACTIVATION_LANDING.NBC },
            ])
          }
          this._setSecondarySubscription()
          useRouterBackDisabled().set(this._disableRouterBack())
          ModalManager.open(ModalTypes.ACTIVATION, {
            landing: ACTIVATION_LANDING.NBC,
          })
        }
      },
      class TvProvider extends this {
        override _getFocused() {
          return this.tag('TvProviderContent.LinkButton') || this
        }
        override _handleUp() {
          this._setState(MyProfileStates.NBCUserProfile)
        }
        override _handleEnter() {
          if (AuthenticationSingleton.isAuthenticated()) {
            return this._setState(MyProfileStates.LogoutConfirmation, [
              { type: ACTIVATION_LANDING.MVPD },
            ])
          }
          useRouterBackDisabled().set(this._disableRouterBack())
          ModalManager.open(ModalTypes.ACTIVATION, {
            landing: ACTIVATION_LANDING.MVPD,
            referrer: ACTIVATION_REFERRER.MVPD,
          })
        }
      },
      class LogoutConfirmation extends this {
        _args: any
        _preState: any
        override $enter({ prevState }: any, args: any) {
          this._preState = prevState
          if (args) {
            this._args = args
            this.widgets.confirmationdialog._setMessage(
              Language.translate(
                this._args.type === ACTIVATION_LANDING.MVPD
                  ? 'unlink_tv_provider'
                  : 'nbc_profile_disconnect'
              ),
              Language.translate('cancel'),
              Language.translate('confirm')
            )
            this.widgets.confirmationdialog.visible = true
            Router.focusWidget('ConfirmationDialog')
          }
        }

        confirmBtnClicked() {
          this.hideConformationDialog()
          if (this._args.type === ACTIVATION_LANDING.MVPD) {
            AuthenticationSingleton.logout().then(() => {
              this._setTvProviderData()
              this._setState(MyProfileStates.LogoutConfirmation_Notification)
              // Do this right after so the sign in buttons are focused and accessible.
              this._setState('TvProvider')
            })
          } else {
            logoutUserProfile().then(() => {
              this._setState(MyProfileStates.LogoutConfirmation_Notification)
              // Do this right after so the sign in buttons are focused and accessible.
              this._setState(MyProfileStates.NBCUserProfile)
            })
          }
        }

        cancelBtnClicked() {
          this.hideConformationDialog()
          this.setPreviousState()
        }

        setPreviousState() {
          this._setState(this._preState)
        }

        hideConformationDialog() {
          this.widgets.confirmationdialog.visible = false
          Router.focusPage()
        }

        static override _states() {
          return [
            class Notification extends LogoutConfirmation {
              override _args: any
              _notificationTimeout: any
              override $enter() {
                const isMVPD = this._args.type === ACTIVATION_LANDING.MVPD
                this.widgets.notification._setNotificationMsg(
                  'images/logout.png',
                  false,
                  Language.translate(isMVPD ? 'tv_provider_unlinked' : 'nbc_profile_signed_out')
                )
                this.widgets.notification.visible = true
                if (!isMVPD) sendMetric(EVENTS.NBC_PROFILE_SIGN_OUT)
                this._setNotificationTimeOut()
              }

              _setNotificationTimeOut() {
                this._notificationTimeout = setTimeout(() => {
                  this.widgets.notification.visible = false
                  // Falls through to last case and resets ui to signed out state.
                  this._args.type === ACTIVATION_LANDING.MVPD
                    ? this._setTvProviderData()
                    : this._setNBCUserProviderData()

                  if (this._notificationTimeout) {
                    clearTimeout(this._notificationTimeout)
                  }
                }, 2000)
              }
            },
          ]
        }
      },
    ]
  }
}
