export const MPARTICLE_DEFAULT_ATTR_VALUE = 'None'

export enum TOKEN_TYPE {
  AUTH_KILL = 'Auth Kill',
  TEMP_PASS = 'TempPass',
  MVPD = 'MVPD',
  FREE_CONTENT_GATING = 'Free Content Gating',
}

export enum MPARTICLE_CLICK_STATES {
  LIVE = 'Live',
  UPCOMING = 'Upcoming',
  FER = 'Full Event Replay',
}

export const CONVIVA_DEFAULT_ATTR_VALUE = 'N/A'

export const ADOBE_DEFAULT_ATTR_VALUE = 'unknown'

export enum MPARTICLE_ERROR_CONTEXTS {
  vod = 'vod',
  live = 'live',
}

export enum PAGE_NAME {
  main = 'Homepage',
  shows = 'All Shows Page : All',
  showHome = 'Show Home Page',
  movieHome = 'Movie Home Page',
  videoPlayer = 'Video Player',
  videoEndCard = 'Video End Card',
  settings = 'Settings',
  about = 'About',
  closedCaptions = 'Closed Captions',
  contactUs = 'Contact Us',
  faq = 'FAQ',
  language = 'Language',
  myProfile = 'My Profile',
  activation = 'Activation',
  error = 'Error',
  creditConfirmation = 'Credit Confirmation',
  search = 'Search',
  linearVideoPlayer = 'Linear Video Player',
  allBrands = 'All Brands Page',
  brandLanding = 'Brand Landing Page',
  liveAndUpcoming = 'LIVE & Upcoming Page',
  sports = 'All Sports Page',
  replays = 'Replays',
  olympics = 'Olympics Hub',
  olympicsReplays = 'Olympics Replays Page',
  olympicsHighlights = 'Olympics Highlights Page',
  softContentGating = 'Free Content Soft Gating Modal',
  hardContentGating = 'Free Content Hard Gating Modal',
  showExcludedFromCredits = 'Show Excluded from Credits',
  waysToWatch = 'Ways to Watch',
}

export enum PAGE_TYPE {
  main = 'Homepage',
  shows = 'Shows',
  showHome = 'Show Home Page',
  movieHome = 'Movie Home Page',
  videoPlayer = 'Video Player',
  settings = 'Settings',
  authFunnel = 'Auth Funnel',
  error = 'Error',
  search = 'Search',
  linearVideoPlayer = 'Linear Video Player',
  brands = 'Brands',
  brandLanding = 'Brand Landing Page',
  viewMore = 'View More',
  live = 'Live',
  sports = 'Sports',
  olympics = 'Olympics Hub',
}

export enum SIGN_IN_TYPE {
  google = 'Google',
  facebook = 'Facebook',
  apple = 'Apple',
  email = 'Email',
}

export enum CUSTOM_SHELF_TYPE {
  regular = 'Regular',
  premium = 'Premium',
}

export enum CUSTOM_LEAGUE {
  olympics = 'Paris 2024 Olympics',
}

export const OLYMPICS_FALLBACK_VALUE = 'Olympics'
