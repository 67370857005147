import { Log, Router, Storage } from '@lightningjs/sdk'
import Request from '@lightningjs/sdk/src/Router/model/Request'

import { bootApp } from './api/Api'
import { EVENTS } from './lib/analytics/types'
import { sendMetric } from './lib/analytics/Analytics'
import { getIsDayZero, isXclass } from './helpers'
import GlobalNavigationSingleton from './helpers/globalNavigation'
import { ROUTE, STORAGE_KEYS } from './constants'
import routerConfig from './routes'
import { EpgGuideController } from './components/EpgGuideV2/EpgGuideController'
import ModalManager, { ModalTypes } from './lib/ModalManager'
import LaunchDarklySingleton from './lib/launchDarkly/LaunchDarkly'
import LaunchDarklyFeatureFlags from './lib/launchDarkly/LaunchDarklyFeatureFlags'
import { setView as datadogSetView } from './lib/Datadog'

class RouterConfig implements Router.Config {
  updateHash: any
  bootFailed = false
  constructor() {
    if (isXclass()) {
      this.updateHash = false
    }
  }
  root = ROUTE.home
  boot = async () => {
    try {
      await bootApp()
      this.bootFailed = false
    } catch (error) {
      this.bootFailed = true
    }
  }
  routes = routerConfig
  _events: {
    navigation: any | null
  } = {
    navigation: null,
  }
  set events(cb: any) {
    if (typeof cb !== 'object') {
      return
    }
    Object.keys(cb).forEach((key) => {
      if (key in this._events && typeof cb[key] === 'function') {
        this._events[key as keyof typeof this._events] = cb[key]
      }
    })
  }
  beforeEachRoute = (_: string, request: Request) => {
    const path = request.route?.path
    datadogSetView(request.hash)

    if (path && !isPlayerPath(path)) {
      EpgGuideController.clear()
    }
    return Promise.resolve(true)
  }
  // Trigger mparticle analytics each time a new route loads.
  // This event seems to only work in the 4.x.x sdk.
  afterEachRoute = (request: Request) => {
    if (this.bootFailed) {
      Router.navigate(ROUTE.error, { reBoot: true })
      this.bootFailed = false
      return
    }
    if (this._events.navigation) {
      this._events.navigation()
    }
    const path = request.route?.path
    if (!path || isPathSkippable(path)) return
    if (!request.register.get('preventClickMetric')) {
      const hash = request?.hash || ''
      const name = GlobalNavigationSingleton.items?.find?.(({ route }: any) => route === hash)
        ?.label
      // For TESTING PURPOSE ONLY
      if (name === 'Home' && performance.getEntriesByName('startRouting').length > 0) {
        performance.mark('endRouting')
        // Measure the duration between marks
        performance.measure('routingDuration', 'startRouting', 'endRouting')

        const measures = performance.getEntriesByName('routingDuration')
        Log.info('Routing Duration:', measures)
      }
    }

    // Additional Paths done async.
    if (
      isPathSkippable(path, [
        ROUTE.showHome,
        ROUTE.video,
        ROUTE.live,
        ROUTE.watch,
        ROUTE.movieHome,
        ROUTE.olympicsReplays,
        ROUTE.olympicsHighlights,
      ])
    )
      return
    sendMetric(EVENTS.PAGE_LOAD, getEventDataFromRequest(request))

    if (
      !LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.ctvOnboarding) ||
      Storage.get(STORAGE_KEYS.ONBOARDING_WAS_SHOWN) ||
      !getIsDayZero()
    ) {
      Router.focusPage()
      return
    }
    ModalManager.open(ModalTypes.ONBOARDING_MODAL)
  }
}

export default new RouterConfig()

export const isPathSkippable = (path: any, additionalPaths: ROUTE[] = []) =>
  [ROUTE.boot, ROUTE.notFound, ROUTE.error, ...additionalPaths].includes(path)

const isPlayerPath = (path: string) =>
  [ROUTE.live, ROUTE.watch, ROUTE.video].includes(path as ROUTE)

const getEventDataFromRequest = (request: any) => {
  return {
    hash: request._hash,
    path: request._route._cfg.path,
    // First load of shows page only use default brand and cat.
    // Subsequent changes will track as separate page loads.
    brand: 'None',
    category: request._hash === ROUTE.shows ? 'Popular' : 'None',
  }
}
