import { Language } from '@lightningjs/sdk'

import { ActivationModalData } from './Activation'
import OnboardingActivation from './OnboardingActivation'

import { useRouterBackDisabled } from './hooks/useRouterBackDisabled'
import { ACTIVATION_LANDING, ACTIVATION_REFERRER } from './constants'
import { templateDeepMerge } from '../../../helpers'
import { COLORS, FONT_FACE, VERTICAL_ALIGN } from '../../../constants'
import { LoadingStateFactory } from './states/LoadingStateFactory'
import { NewCodeStateFacrory } from './states/NewCodeStateFacrory'
import { sendMetric } from '../../../lib/analytics/Analytics'
import { EVENTS } from '../../../lib/analytics/types'

export default class CreditsFlowActivation extends OnboardingActivation {
  protected cancelButtonLabel = Language.translate('maybe-later')
  override _routerBackDisabled = false
  override _showLinkingMessage = false

  static override _template() {
    return templateDeepMerge(
      super._template(),
      {
        ActivationScreen: {
          flex: {
            paddingTop: 219,
          },
          Title: {
            flexItem: {
              marginBottom: 69,
            },
            text: {
              fontSize: 56,
              fontFace: FONT_FACE.light,
              textColor: COLORS.white5,
              lineHeight: 70,
              verticalAlign: VERTICAL_ALIGN.middle,
              maxLines: 2,
              wordWrapWidth: 1570,
            },
          },
          Content: {
            CodeContent: {
              CancelButton: {
                label: this.bindProp('cancelButtonLabel',
                  ({ cancelButtonLabel }: any) => (cancelButtonLabel.toUpperCase())),
              },
            },
          },
        },
      },
    )
  }

  override _init() {
    super._init()
    const activationScreen = this.tag('ActivationScreen')
    const logoWrapper = activationScreen?.tag('LogoWrapper')
    if (logoWrapper) activationScreen.childList.remove(logoWrapper)
  }

  override _setModalData(modalData: ActivationModalData): void {
    useRouterBackDisabled().set(true)
    this.landing = modalData.landing || ACTIVATION_LANDING.NBC
    this._titleTextKey = this._landing === ACTIVATION_LANDING.MVPD
      ? 'credits_flow_activation_mvpd_title' : 'credits_flow_activation_profile_title'
    this._program = modalData.program
    this._stream = modalData.stream
    this._resolveCallback = modalData.resolveCallback
    this._closeCallback = modalData.closeCallback
    this._referrerType = ACTIVATION_REFERRER.CREDITS_FLOW
    this._start()
  }

  override _setTranslation() {
    super._setTranslation()
    this.cancelButtonLabel = Language.translate('maybe-later')
  }

  static override _states() {
    return [
      LoadingStateFactory(this),
      NewCodeStateFacrory(this),
      class Cancel extends this {
        override _getFocused() {
          return this.tag('CancelButton')
        }
        override _handleEnter(): void {
          sendMetric(EVENTS.CLICK, {
            name: 'Maybe Later',
          })
          this._closeCallback?.()
        }
        override _handleUp() {
          this._setState('NewCode')
        }
      },
    ]
  }
}
