import { Lightning, Router, Utils } from '@lightningjs/sdk'

import PeacockBenefit from './PeacockBenefit'
import PeacockGradientButton from './PeacockGradientButton'

import { COLORS, FLEX_DIRECTION, ROUTE } from '../../../../../constants'
import { isExternalLinkingAllowed } from '../../../../../helpers'
import { sendMetric } from '../../../../../lib/analytics/Analytics'
import { EVENTS } from '../../../../../lib/analytics/types'

const ITEM_CLICKED_NAME = 'Peacock Stream Now'

export default class PeacockTile extends Lightning.Component {
  onClick: () => void
  program: any
  static override _template(): any {
    return {
      h: 478,
      w: 596,
      texture: Lightning.Tools.getRoundRect(596, 478, 20, 1, COLORS.white, true, COLORS.black),
      Image: {
        x: 183,
        y: 45,
        h: 70,
        w: 230,
        src: Utils.asset('images/logos/logo-peacock.png'),
      },
      Benefits: {
        y: 150,
        x: 55,
        flex: {
          direction: FLEX_DIRECTION.column,
        },
        Item1: {
          type: PeacockBenefit,
          image: Utils.asset('images/remote-control.png'),
          title: this.bindProp('benefitTitle1'),
        },
        Item2: {
          type: PeacockBenefit,
          image: Utils.asset('images/tv.png'),
          title: this.bindProp('benefitTitle2'),
        },
      },
      Button: {
        y: 348,
        x: 88,
        w: 420,
        h: 50,
        type: PeacockGradientButton,
        title: this.bindProp('btnTitle'),
        unfocusBgAlpha: 0.7,
        unfocusLabelAlpha: 0.6,
      },
    }
  }

  override _getFocused() {
    return this.tag('Button')
  }

  override _handleEnter() {
    const programInfo = {
      brand: this.program?.brand?.title,
      show: this.program?.series,
      season: this.program?.seasonNumber,
    }

    sendMetric(EVENTS.CLICK, {
      name: ITEM_CLICKED_NAME,
      ...programInfo,
    })

    if (isExternalLinkingAllowed()) {
      this.fireAncestors('$exitToPeacock')
    } else {
      Router.navigate(ROUTE.home)
    }
  }
}
