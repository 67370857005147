import { Language, Lightning, Router, Utils } from '@lightningjs/sdk'
import BaseComponent from '../../../components/base'
import TextButton from '../../../components/buttons/TextButton'
import {
  ALIGN_ITEMS,
  COLORS,
  FLEX_DIRECTION,
  FONT_FACE,
  JUSTIFY_CONTENT,
  LANGUAGES,
  ROUTE,
  SCREEN_SIZE,
  TEXT_ALIGN,
  VERTICAL_ALIGN,
} from '../../../constants'
import LaunchDarklySingleton from '../../../lib/launchDarkly/LaunchDarkly'
import LaunchDarklyFeatureFlags from '../../../lib/launchDarkly/LaunchDarklyFeatureFlags'
import { sendMetric } from '../../../lib/analytics/Analytics'
import { EVENTS } from '../../../lib/analytics/types'
import { ACTIVATION_LANDING } from './constants'
import { RouterPage } from '../../../../types/global'
import { isExternalLinkingAllowed } from '../../../helpers'
import ModalManager, { ModalTypes } from '../../../lib/ModalManager'

export default class TempPassConcluded extends BaseComponent<RouterPage> {
  _focusIndex: any
  _launchDarklyTempPassData: any
  _params: any
  _modalTitle1: string
  _modalTitle2: string
  _peacockTitle: string
  _peacockCTA: string
  _mvpdTitle: string
  _mvpdCTA: string

  override _construct() {
    this._focusIndex = 0
    this._launchDarklyTempPassData = LaunchDarklySingleton.getFeatureFlag(
      LaunchDarklyFeatureFlags.tempPass
    )
  }

  static override _template() {
    return {
      rect: true,
      color: COLORS.black,
      w: SCREEN_SIZE.width,
      h: SCREEN_SIZE.height,
      Background: {
        w: (w: any) => w,
        h: (h: any) => h,
        alpha: 0.5,
        rect: true,
        color: COLORS.dark,
      },
      TitleContainer: {
        y: 160,
        w: (w: any) => w,
        h: 140,
        flex: {
          direction: FLEX_DIRECTION.column,
          alignItems: ALIGN_ITEMS.center,
        },
        Title1: {
          text: {
            textAlign: TEXT_ALIGN.center,
            fontSize: 45,
            fontFace: FONT_FACE.light,
            textColor: COLORS.white,
            verticalAlign: VERTICAL_ALIGN.middle,
            text: this.bindProp('_modalTitle1'),
          },
        },
        Title2: {
          text: {
            textAlign: TEXT_ALIGN.center,
            fontSize: 60,
            fontFace: FONT_FACE.light,
            textColor: COLORS.white,
            verticalAlign: VERTICAL_ALIGN.middle,
            text: this.bindProp('_modalTitle2'), // if _launchDarklyTempPassData forkCopy is undefined, this will be the default
          },
        },
      },
      Options: {
        w: (w: any) => w,
        h: 465,
        y: 370,
        Peacock: {
          w: 706,
          h: 465,
          x: 222,
          Background: {
            w: (w: any) => w,
            h: (h: any) => h,
            rect: true,
            color: COLORS.thunder1,
            alpha: 0.6,
            shader: { type: Lightning.shaders.RoundedRectangle, radius: 20 },
          },
          ContentContainer: {
            w: (w: any) => w,
            h: (h: any) => h,
            flex: {
              direction: FLEX_DIRECTION.column,
              alignItems: ALIGN_ITEMS.center,
              justifyContent: JUSTIFY_CONTENT.center,
            },
            Title: {
              text: {
                textAlign: TEXT_ALIGN.center,
                fontSize: 40,
                fontFace: FONT_FACE.light,
                textColor: COLORS.white,
                verticalAlign: VERTICAL_ALIGN.middle,
                text: this.bindProp('_peacockTitle'),
                wordWrap: true,
                wordWrapWidth: 400,
              },
            },
            Image: {
              h: 85,
              w: 275,
              src: Utils.asset('images/logos/logo-peacock.png'),
              flexItem: {
                margin: 20,
                marginBottom: 40,
              },
            },
            PeacockLinkButton: {
              w: 600,
              h: 65,
              type: TextButton,
              radius: 29,
              fontSize: 26,
              fontFace: FONT_FACE.regular,
              focusFontColor: COLORS.dark,
              unfocusFontColor: COLORS.lightGray3,
              focusBackGroundColor: COLORS.lightGray3,
              unfocusBackgroundColor: COLORS.transparent,
              label: this.bindProp('_peacockCTA'),
              autoWidth: false,
              padding: 0,
              strokeWidth: 2,
              strokeColor: COLORS.white,
            },
          },
        },
        TVProvider: {
          w: 706,
          h: 465,
          x: 992,
          Background: {
            w: (w: any) => w,
            h: (h: any) => h,
            rect: true,
            color: COLORS.thunder1,
            alpha: 0.6,
            shader: { type: Lightning.shaders.RoundedRectangle, radius: 20 },
          },
          ContentContainer: {
            w: (w: any) => w,
            h: (h: any) => h,
            flex: {
              direction: FLEX_DIRECTION.column,
              alignItems: ALIGN_ITEMS.center,
              justifyContent: JUSTIFY_CONTENT.center,
            },
            Title: {
              text: {
                textAlign: TEXT_ALIGN.center,
                fontSize: 40,
                fontFace: FONT_FACE.light,
                textColor: COLORS.white,
                verticalAlign: VERTICAL_ALIGN.middle,
                text: this.bindProp('_mvpdTitle'),
                wordWrap: true,
                wordWrapWidth: 400,
              },
            },
            Image: {
              h: 82,
              w: 117,
              src: Utils.asset('images/logos/logo-tv-link.png'),
              flexItem: {
                margin: 20,
                marginBottom: 40,
              },
            },
            TVLinkButton: {
              w: 600,
              h: 65,
              type: TextButton,
              radius: 29,
              fontSize: 26,
              fontFace: FONT_FACE.regular,
              focusFontColor: COLORS.dark,
              unfocusFontColor: COLORS.lightGray3,
              focusBackGroundColor: COLORS.lightGray3,
              unfocusBackgroundColor: COLORS.transparent,
              label: this.bindProp('_mvpdCTA'),
              autoWidth: false,
              padding: 0,
              strokeWidth: 2,
              strokeColor: COLORS.white,
            },
          },
        },
      },
    }
  }

  set modalData(params: any) {
    this._params = params
  }

  override _init() {
    this._updateTranslations()
    this.stage.setClearColor(COLORS.dark)
    if (
      this._launchDarklyTempPassData?.forkCopy?.en &&
      this._launchDarklyTempPassData?.forkCopy?.es
    ) {
      this.tag('TitleContainer.Title2').text.text =
        Language.get() === LANGUAGES.DEFAULT
          ? this._launchDarklyTempPassData.forkCopy.en
          : this._launchDarklyTempPassData.forkCopy.es
    }

    if (isExternalLinkingAllowed()) {
      this.tag('PeacockLinkButton').patch({
        label: Language.translate('tempPass-peacock-cta').toUpperCase(),
      })
    } else {
      this.tag('PeacockLinkButton').patch({
        label: Language.translate('peacock-cta-ok').toUpperCase(),
      })
    }
  }

  override _active() {
    this._updateTranslations()
    sendMetric(EVENTS.MODAL_LOAD, {
      modalName: 'Your Live Sports Free Preview has concluded',
      modalType: 'TempPass',
    })
  }

  _updateTranslations() {
    this._modalTitle1 = Language.translate('tempPass-top-1')
    this._modalTitle2 = Language.translate('tempPass-top-2')
    this._peacockTitle = Language.translate('tempPass-peacock-title')
    this._peacockCTA = Language.translate('tempPass-peacock-cta').toUpperCase()
    this._mvpdTitle = Language.translate('tempPass-mvpd-title')
    this._mvpdCTA = Language.translate('tempPass-mvpd-cta').toUpperCase()
  }

  override _handleBack() {
    ModalManager.close()
  }

  override _handleDown() {
    return true
  }

  override _handleUp() {
    return true
  }

  override _handleLeft() {
    this._focusIndex = 0
  }

  override _handleRight() {
    this._focusIndex = 1
  }

  override _handleEnter() {
    if (this._focusIndex === 0) {
      if (isExternalLinkingAllowed()) {
        sendMetric(EVENTS.CLICK, {
          name: 'TempPass - Stream on Peacock',
        })

        const activePage = Router.getActivePage()
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        const exitDialog = activePage?.widgets?.exitdialog
        if (exitDialog) {
          exitDialog.isTempPass = true
          exitDialog.visible = true
          exitDialog.program = this._params.program
          Router.focusWidget('ExitDialog')
        } else {
          Router.navigate(ROUTE.home)
        }
      } else {
        sendMetric(EVENTS.CLICK, {
          name: 'TempPass - Stream on Peacock',
        })
        Router.navigate(ROUTE.home)
      }
    } else {
      sendMetric(EVENTS.CLICK, {
        name: 'TempPass - Link TV Provider',
      })
      ModalManager.open(ModalTypes.ACTIVATION, {
        ...this._params,
        landing: ACTIVATION_LANDING.MVPD,
      })
    }
  }

  override _getFocused() {
    if (this._focusIndex === 0) {
      return this.tag('Options.Peacock.ContentContainer.PeacockLinkButton') || this
    } else {
      return this.tag('Options.TVProvider.ContentContainer.TVLinkButton') || this
    }
  }
}
