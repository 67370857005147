import { Language, Log, Router } from '@lightningjs/sdk'
import { debounce } from 'lodash'

import { LongScroll } from '../../components'
import LongScrollPage from '../LongScrollPage'
import PagingList from '../../components/search/PagingList'
import PagingListTile from '../../components/search/PagingListTile'
import InputField from '../../components/search/InputField'
import algolia from '../../api/algolia/algolia'
import { default as SearchKeyboard, searchConfig } from '../../components/search/SearchKeyboard'
import { SectionsSpawner } from '../../api/spawners'
import SearchOutcomeType from '../../lib/analytics/mParticle/model/SearchOutcomeType'

import { EVENTS } from '../../lib/analytics/types'
import {
  removeNotMappedTypeContents,
  Resource,
  ResourceObject,
  setSmooth,
  transformResources,
} from '../../helpers'
import {
  COLORS,
  ENTITY_TYPES,
  FLEX_DIRECTION,
  MAX_LINES_SUFFIX,
  SCREEN_SIZE,
  SHOWS_SHELF_TITLE,
} from '../../constants'
import { sendMetric } from '../../lib/analytics/Analytics'

import AuthenticationSingleton from '../../authentication/Authentication'
import TVPlatform from '../../lib/tv-platform'
import { ErrorType } from '../../lib/tv-platform/types'
import { useRequest } from '../../lib/useRequest'
import { SearchFallbackRequestConfig, SearchFeaturedRequestConfig } from './request'

export default class Search extends LongScrollPage {
  _algoliaSearch: any
  _availableItems: any
  _hasResults: any
  _items: any
  _initialQuery?: string
  _previousSearchTerm?: string
  _focusSection?: string
  _callSearchDebounced = debounce((queryTerm: string, callback?: () => void) => {
    this.tag('PagingList').index = 0
    this.tag('PagingList')._reset()
    this._algoliaSearch
      .doSearch({ query: queryTerm, page: 0 })
      .then(async (data: any) => {
        this._previousSearchTerm = queryTerm

        const isMvpd = AuthenticationSingleton.isAuthenticated()
        const mvpdData = await AuthenticationSingleton.getMvpdData()

        let resources: Array<Resource> = []
        let resourceAuthorization: ResourceObject = {}

        if (mvpdData?.preAuthData?.resources) {
          resources = mvpdData.preAuthData.resources
          resourceAuthorization = transformResources(resources)
        }

        const filteredHits = removeNotMappedTypeContents(data.hits).filter(({ data }: any) => {
          // Early exit from the function if mvpd is empty
          if (!isMvpd) return data
          const resourceId = data?.resourceId || data?.event?.resourceId || data?.brand?.resourceId

          if (isMvpd && resourceId) {
            return data
          } else {
            Log.info('Binge Hidden Resource Ids::', resourceId, 'Authorization:')
          }
        })

        const hasData = filteredHits.length > 0

        if (filteredHits) {
          this.positionContainers(hasData)
          this.tag('ListTitle').children = this.createFlexText([
            Language.translate('searchPage-results-found'),
            {
              text: data.nbHits ? (data.nbHits > 100 ? '100+' : filteredHits?.length) : 0,
              font: 'SemiBold',
            },
            Language.translate('searchPage-results-related'),
          ])
          if (!hasData) {
            this._hasResults = false
            this.showError(this.tag('InputField').value)
          } else {
            this._hasResults = true
            this._availableItems = data.nbHits
            this.tag('Error').alpha = 0
            this.setSearchItems(filteredHits)
            callback?.()
          }
        }
      })
      .catch((err: any) => {
        TVPlatform.reportError({
          type: ErrorType.NETWORK,
          description: 'Search error',
          payload: err,
        })
        this._hasResults = false
        this.positionContainers(false)
        this.showError(this.tag('InputField').value)
      })
  }, 1000)

  static override _template() {
    return {
      Wrapper: {
        Scroller: {
          type: LongScroll,
          scrollFocus: 0.5,
          x: 270,
          y: 458,
          transitions: {
            y: {
              duration: 0.3,
              timingFunction: 'cubic-bezier(0.20, 1.00, 0.80, 1.00)',
            },
          },
        },

        SearchResultsWrapper: {
          alpha: 0,

          ListTitle: {
            x: 210,
            y: 448,
            color: COLORS.lightGray6,
            flex: { direction: FLEX_DIRECTION.row },
          },

          PagingList: {
            w: 1606,
            h: SCREEN_SIZE.height,
            x: 157,
            y: 533,
            type: PagingList,
            orientation: 'vertical',
            marginStart: 300,
            marginEnd: 600,
            signals: {
              indexChanged: true,
              requestMoreItems: true,
              onClickItem: true,
            },
            spacing: 25,
          },
        },

        InputField: {
          type: InputField,
          h: 115,
          w: 1310,
          x: 304,
          y: 196,
        },

        Keyboard: {
          x: 191,
          y: 351,
          w: 1590,
          h: 47,
          layout: 'ABC',
          config: searchConfig,
          type: SearchKeyboard,
          signals: {
            onValueChanged: true,
            onSearch: true,
          },
          maxCharacters: 32,
        },

        Error: {
          alpha: 0,
          y: 448,
          x: 270,
          w: 1500,
          /*          text: {
            wordWrap: true,
            fontFace: FONT_FACE.light,
            lineHeight: 52,
            fontSize: 36,
          },*/
          flex: { direction: FLEX_DIRECTION.row, wrap: true },
        },
      },
    }
  }

  override _init() {
    this._previousSearchTerm = undefined
    this._algoliaSearch = algolia.search()
  }

  override _detach() {
    setSmooth(this.widgets.loader, 'visible', 1)
  }

  override _focus() {
    if (this._focusSection === 'scroller') {
      this._setState('Scroller')
      return
    }

    if (this._focusSection === 'pagingList') {
      this._setState('PagingList')
      return
    }

    this._setState('Keyboard')
    this.changeWrapperStyling(0, 1)
  }

  override _active() {
    this.stage.setClearColor(COLORS.dark)
    setSmooth(this.widgets.loader, 'visible', 0)
    if (this._initialQuery && this._callSearchDebounced) {
      this._callSearchDebounced(this._initialQuery, () => {
        this._setState('PagingList')
      })
      this.tag('InputField').value = this._initialQuery
    }
  }

  override set params(params: any) {
    if (params.query) {
      // Using hash because LightningJS router doesn't play nice with search terms with spaces
      this._initialQuery = decodeURI(window.location.hash.replace('#search/', '')) || params.query
    }
  }

  set apiData(v: any) {
    setSmooth(this.widgets.loader, 'visible', 0)
    if (v?.data) {
      this.tag('Scroller')._reset()
      Log.info('Search data', v.data)
      SectionsSpawner(this.stage, [v.data]).then((containers) => {
        this.containers = containers
      })
    }
  }

  set containers(v: any) {
    this.tag('Scroller').add(v)
  }

  async load(): Promise<void> {
    try {
      this.apiData = await useRequest(SearchFeaturedRequestConfig()).fetch()
    } catch (e) {
      Log.error('Failed to load search featured data. Using the fallback...')
      try {
        const data = await useRequest(SearchFallbackRequestConfig()).fetch()
        const showsShelf = data?.sections?.filter(
          (section: any) =>
            typeof section?.data?.listTitle === 'string' &&
            section?.data?.listTitle.includes(SHOWS_SHELF_TITLE)
        )
        this.apiData = {
          data: showsShelf?.length ? showsShelf?.[0] : [],
        }
      } catch (e: any) {
        TVPlatform.reportError({
          type: ErrorType.NETWORK,
          description: e.description,
          code: e.code,
        })
      }
    }
  }

  changeWrapperStyling(y: any, alpha: any) {
    this.tag('Wrapper').setSmooth('y', y)
    this.widgets.menu.setSmooth('alpha', alpha)
  }

  showError(value: any) {
    this.tag('Error').children = this.createFlexText([
      Language.translate('searchPage-results-noResults'),
      {
        text: `"${value || MAX_LINES_SUFFIX}"`,
        font: 'SemiBold',
      },
      {
        text: Language.translate('searchPage-results-noResults2'),
        flexBasis: '100%',
        w: SCREEN_SIZE.width,
      },
    ])
    this.tag('Error').setSmooth('alpha', 1)
  }

  positionContainers(hasData: any) {
    this.patch({
      Wrapper: {
        Scroller: {
          smooth: {
            alpha: hasData ? 0 : 1,
            y: hasData ? 458 : 612,
          },
        },
        SearchResultsWrapper: {
          smooth: {
            alpha: hasData ? 1 : 0,
          },
        },
      },
    })
  }

  reset() {
    this.tag('Error').setSmooth('alpha', 0)
    this.tag('Scroller').setSmooth('y', 458)
    this.tag('Scroller').setSmooth('alpha', 1)
    this.tag('SearchResultsWrapper').setSmooth('alpha', 0)
    this.tag('SearchResultsWrapper').setSmooth('y', 0)
    this.widgets.menu.setSmooth('alpha', 1)

    this._focusSection = undefined
    this._hasResults = false
  }

  _onSearchResultEvent(type: any, item?: any) {
    sendMetric(EVENTS.SEARCH_RESULT, {
      type: type,
      item: item,
      results: this._availableItems,
      searchTerm: this._previousSearchTerm,
    })
    if (item) {
      sendMetric(EVENTS.CONTENT_CLICK, {
        entity: { entityType: ENTITY_TYPES.SEARCH_RESULTS, analytics: item },
        shelf: {
          customPosition: 1,
          position: item.index + 1,
        },
      })
    }
  }

  $setSearchItems(items: any) {
    this.setSearchItems(items)
  }

  setSearchItems(items: any) {
    this._items = items
    this.tag('PagingList').patch({
      items: this._items.map((item: any) => {
        return {
          type: PagingListTile,
          w: 1516 + 12,
          h: 275 + 12,
          item: item,
          signals: { onClickItem: true },
        }
      }),
      availableItems: this._availableItems,
    })
  }

  requestMoreItems({ offset }: any) {
    //Not a huge fan of this. When this doSearch completes it will return to src/components/search/Paginglist.js
    //Where it will filter the results in the indexChanged function.
    //Took me some time to find so leaving this note here for anyone else looking for the results of this
    return this._algoliaSearch.doSearch({
      query: this.tag('InputField').value,
      page: Math.floor(offset / 20),
    })
  }

  onClickItem(item: any) {
    this._onSearchResultEvent(SearchOutcomeType.selectResult, item)
  }

  createFlexText(text: any) {
    return text.map((t: any) => {
      return {
        w: t.w || 0,
        flexItem: { marginRight: 10 },
        text: {
          text: t.text || t,
          fontFace: t.font ? t.font : 'Light',
        },
      }
    })
  }

  override _handleBack() {
    this.widgets.menu.setSmooth('alpha', 1)
    this._onSearchResultEvent(SearchOutcomeType.browse)
    // Since is this page isn't truly navigated to (using the router)
    // I'm reset setting the state of event to base
    this._previousSearchTerm = undefined
    return false
  }

  static override _states() {
    return [
      class Keyboard extends this {
        override _hasResults: any
        override _previousSearchTerm: any

        override $enter() {
          this._focusSection = 'keyboard'
          if (!this.tag('Error').visible) this.tag('Scroller').setSmooth('y', 458)
        }

        override _handleUp() {
          Router.focusWidget('Menu')
        }

        // @ts-expect-error TS(2416): Property '_handleBack' in type 'Keyboard' is not a... Remove this comment to see the full error message
        _handleBack(e: any) {
          Router.focusWidget('Menu')
          e.preventDefault()
          e.stopPropagation()
        }

        override _handleDown() {
          if (this._hasResults) {
            this._setState('PagingList')
            return
          }
          this._setState('Scroller')
        }

        override _getFocused() {
          return this.tag('Keyboard') || this
        }

        onValueChanged(value: any) {
          const queryTerm = value.value

          if (queryTerm.length === this.tag('InputField').maxCharacters) return
          this.tag('InputField').value = queryTerm

          if (queryTerm.length === 0) {
            this.reset()
            return
          }

          if (this._previousSearchTerm && this._previousSearchTerm !== queryTerm) {
            this._onSearchResultEvent(SearchOutcomeType.searchAgain)
          }

          this._callSearchDebounced(queryTerm)
        }
      },
      class Scroller extends this {
        override $enter() {
          if (this.tag('Error').alpha === 1) {
            this.changeWrapperStyling(-325, 0)
            return
          }

          this.changeWrapperStyling(-160, 0)
          this._focusSection = 'scroller'
        }

        override $exit() {
          this.changeWrapperStyling(0, 1)
        }

        override _handleUp() {
          this._setState('Keyboard')
        }

        override _getFocused() {
          return this.tag('Scroller') || this
        }

        override _handleDown() {
          // block
        }

        override _handleBack() {
          this._focusSection = undefined
          this._setState('Keyboard')
          return true
        }
      },
      class PagingList extends this {
        override _getFocused() {
          return this.tag('PagingList') || this
        }

        override $enter() {
          this._focusSection = 'pagingList'
          this.changeWrapperStyling(-180, 0)
        }

        override $exit() {
          this.changeWrapperStyling(0, 1)
        }

        override _handleUp() {
          this._setState('Keyboard')
        }

        override _handleBack() {
          this._previousSearchTerm = undefined
          this.widgets.menu.setSmooth('alpha', 1)
          Router.focusWidget('Menu')
          this.changeWrapperStyling(0, 0)
          this.reset()
          return true
        }

        indexChanged(event: any) {
          switch (event.newIndex) {
            case 0:
              this.changeWrapperStyling(-180, 0)
              break
            case 1:
              this.changeWrapperStyling(-470, 0)
              break
            case 2:
              this.changeWrapperStyling(-750, 0)
              break
          }
        }
      },
    ]
  }
}
