import { IPlayerEvent } from './IPlayerEvent'
import { PlayerError } from '../../../components/error/PlayerError'
import { PlayerErrorCategory } from '@sky-uk-ott/core-video-sdk-js-core/lib/error/error.enums'
import TVPlatform from '../../../lib/tv-platform'

export class FatalErrorEvent extends IPlayerEvent {
  _error

  constructor(error: any) {
    super()
    this._error = error
  }

  get error() {
    return this._error
  }

  get errorType(): PlayerError {
    switch (true) {
      case (
        this.error.category === PlayerErrorCategory.VSF &&
        this.error.code?.includes('3016') &&
        TVPlatform.getAllowVSFError()
      ):
        return PlayerError.VSF_3016
      default:
        return PlayerError.UNKNOWN
    }
  }
}
