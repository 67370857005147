import { Lightning, Router } from '@lightningjs/sdk'
import ModalManager, { ModalTypes } from '../../lib/ModalManager'
import {
  Activation,
  ContentGatingActivation,
  ContentGatingModal,
  CreditsFlowModal,
  ErrorModal,
  OnboardingActivation,
  OnboardingModal,
  PlayerLoader,
  QrModal,
  TempPassActivation,
  TempPassConcluded,
  UpcomingModal,
} from '../index'
import CreditsFlowActivation from './activation/CreditsFlowActivation'

// @ts-expect-error
export const ModalTypeTagMap: Record<
  ModalTypes,
  { key: keyof Router.Widgets; component: Lightning.Component.Constructor }
> = {
  [ModalTypes.QR_MODAL]: { key: 'QrModal', component: QrModal },
  [ModalTypes.UPCOMING]: { key: 'UpcomingLiveModal', component: UpcomingModal },
  [ModalTypes.PLAYER_ERROR]: { key: 'PlayerErrorModal', component: ErrorModal },
  [ModalTypes.PLAYER_LOADER]: { key: 'PlayerLoaderModal', component: PlayerLoader },
  [ModalTypes.ACTIVATION]: { key: 'Activation', component: Activation },
  [ModalTypes.ACTIVATION_TEMPPASS_MVPD]: { key: 'ActivationTempPassMvpd', component: Activation },
  [ModalTypes.ACTIVATION_TEMPPASS_NBC]: {
    key: 'ActivationTempPassNbc',
    component: TempPassActivation,
  },
  [ModalTypes.TEMPPASS_CONCLUDED]: {
    key: 'TempPassConcluded',
    component: TempPassConcluded,
  },
  [ModalTypes.ONBOARDING_MODAL]: { key: 'OnboardingModal', component: OnboardingModal },
  [ModalTypes.ONBOARDING_ACTIVATION]: {
    key: 'OnboardingActivation',
    component: OnboardingActivation,
  },
  [ModalTypes.SOFT_CONTENT_GATING]: {
    key: 'SoftContentGating',
    component: ContentGatingModal,
  },
  [ModalTypes.HARD_CONTENT_GATING]: {
    key: 'HardContentGating',
    component: ContentGatingModal,
  },
  [ModalTypes.CONTENT_GATING_ACTIVATION]: {
    key: 'ContentGatingActivation',
    component: ContentGatingActivation,
  },
  [ModalTypes.CREDITS_FLOW_MODAL]: {
    key: 'CreditsFlowModal',
    component: CreditsFlowModal,
  },
  [ModalTypes.CREDITS_FLOW_ACTIVATION]: {
    key: 'CreditsFlowActivation',
    component: CreditsFlowActivation,
  },
}

export type ModalHandler = {
  destroy(focusPage: boolean): void
}

export const loadModals = () =>
  Object.values(ModalTypeTagMap).reduce(
    (acc, item, index) => ({
      ...acc,
      [item.key]: {
        type: item.component,
        visible: false,
        zIndex: 200 + index,
      },
    }),
    {}
  )

export const createModal = (ctx: any, type: ModalTypes, payload: any): ModalHandler => {
  const { key } = ModalTypeTagMap[type]
  const modal = ctx.tag(key)
  if (modal) {
    modal.modalData = payload;
    modal.visible = true;
  }
  Router.focusWidget(key)

  return {
    destroy(focusPage = true) {
      modal.visible = false
      if (focusPage) Router.focusPage()
    },
  }
}

export const updateModal = (ctx: any, type: ModalTypes, payload: any) => {
  const { key } = ModalTypeTagMap[type]
  const modal = ctx.tag(key)
  modal.modalData = payload
}

export const getCurrentModalInstance = () => {
  const page = Router.getActivePage()
  const activeModal = ModalManager._activeModal && ModalTypeTagMap[ModalManager._activeModal]
  if (page && activeModal) return page.fireAncestors('$getWidget', activeModal.key)
  return null
}
