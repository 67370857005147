import { Lightning, Utils } from '@lightningjs/sdk'

import { COLORS, FLEX_DIRECTION, FONT_FACE } from '../../../constants'
import { AllowSaleHook } from '../../../lib/cmp/ConsentManagement'
import { Hook } from '../../../lib/Hook'
import LinearGradientShader from '../../../shaders/LinearGradientShader'
import { generateTextElementsByHtml, normalizeTextBlocks } from './helpers'

const SCROLL_STEP = 175

export class AllowForm extends Lightning.Component {
  _hook: Hook<boolean>
  _announce: string[]
  title: string
  _contentRef!: Lightning.Element
  _containerRef!: Lightning.Element

  static override _template() {
    return {
      h: 776,
      w: 1246,
      Title: {
        x: 107,
        color: COLORS.black,
        text: {
          fontFace: FONT_FACE.bold,
          fontSize: 48,
          wordWrapWidth: 1061,
          text: this.bindProp('title'),
        },
      },
      Container: {
        h: 776,
        w: 1246,
        clipping: true,
        Content: {
          w: 1246,
          x: 107,
          y: 0,
          flex: {
            direction: FLEX_DIRECTION.column,
          },
        },
        GradientStart: {
          x: 0,
          y: 0,
          w: 1246,
          h: 30,
          rect: true,
          shader: {
            type: LinearGradientShader,
            colors: [COLORS.white, COLORS.transparent],
            stops: [1.0, 0.0],
            angle: 0,
          },
        },
      },
      GradientEnd: {
        x: 0,
        y: 776,
        w: 1246,
        h: 30,
        mountY: 1,
        rect: true,
        shader: {
          type: LinearGradientShader,
          colors: [COLORS.transparent, COLORS.white],
          stops: [1.0, 0.0],
          angle: 0,
        },
      },
    }
  }

  override _init() {
    this.tag('Title').on('txLoaded', () => {
      const h = this.tag('Title').renderHeight
      this.tag('Container')?.patch({
        y: h,
        h: 776 - h,
      })
    })
    this._hook = AllowSaleHook((v) => {
      this.tag('Tick.Img')?.patch({
        visible: !!v,
      })
    })
    this._contentRef = this.tag('Content')
    this._containerRef = this.tag('Container')
  }

  override _detach() {
    this._hook?.unsubscribe(false)
  }

  get announce() {
    return this._announce
  }

  set announce(announce: string[]) {
    this._announce = [this.title, ...announce]
  }

  set content(content: [checkbox: string, text: string]) {
    const htmlBody = new DOMParser().parseFromString(content[1] as string, 'text/html').body
    const normalizedBody =
      htmlBody.getElementsByTagName('p').length > 0 ? htmlBody : normalizeTextBlocks(htmlBody)
    const paragraphs = Array.prototype.map
      .call([normalizedBody], generateTextElementsByHtml)
      .flat(Infinity)
    if (paragraphs?.length) {
      this.announce = paragraphs.reduce((acc: any[], item: any) => {
        const i =
          item?.text?.text &&
          new DOMParser().parseFromString(item.text.text, 'text/html').body.innerText
        return i ? [...acc, i] : acc
      }, [])

      this.tag('Content').patch({
        Checkbox: {
          w: 1050,
          h: 350,
          rect: true,
          color: COLORS.lightGray14,
          flexItem: {
            marginTop: 30,
            marginBottom: 30,
          },
          CheckboxContainer: {
            shader: {
              type: Lightning.shaders.Inversion,
              amount: 1,
            },
            Tick: {
              x: 1000,
              y: 30,
              mountX: 0.5,
              rect: true,
              h: 40,
              w: 40,
              texture: Lightning.Tools.getRoundRect(40, 40, 0, 4, COLORS.white, false),
              Img: {
                y: 20,
                x: 20,
                mount: 0.5,
                src: Utils.asset('images/tick.png'),
              },
            },
            Text: {
              x: 30,
              y: 20,
              color: COLORS.white,
              text: {
                fontSize: 33,
                fontFace: FONT_FACE.light,
                wordWrapWidth: 1000,
                text: content[0],
              },
            },
          },
        },
        ...paragraphs.reduce(
          (acc: Record<string, any>, p, i) => ({
            ...acc,
            [`Text${i}`]: p,
          }),
          {}
        ),
      })
      this.tag('Checkbox.Text').on(
        'txLoaded',
        (obj: { _source: { renderInfo: { h: number; precision: number } } }) => {
          this.tag('Checkbox').patch({
            h: Math.ceil(
              (Math.ceil(obj._source.renderInfo.h) + 40) / obj._source.renderInfo.precision
            ),
          })
        }
      )
      this.stage.update()
    }
  }

  get focusable() {
    return true
  }

  override _focus() {
    this._setFocused(true)
  }

  override _unfocus() {
    this._setFocused()
  }

  private _setFocused(focused = false) {
    this.tag('Checkbox')?.patch({
      color: focused ? COLORS.mediumGray6 : COLORS.lightGray14,
    })
    this.tag('CheckboxContainer')?.patch({
      shader: {
        amount: focused ? 0 : 1,
      },
    })
  }

  override _handleDown() {
    if (this._contentRef.y + this._contentRef.core.h > this._containerRef.h) {
      this._contentRef.y -= SCROLL_STEP
    } else {
      return false
    }
  }

  override _handleUp() {
    if (this._contentRef.y !== 0) {
      this._contentRef.y += SCROLL_STEP
    } else {
      return false
    }
  }

  override _handleEnter() {
    this._hook.set(!this._hook.value)
  }
}
