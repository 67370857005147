import { Language } from '@lightningjs/sdk'

import OnboardingActivation from './OnboardingActivation'

import { EVENTS } from '../../../lib/analytics/types'
import { sendMetric } from '../../../lib/analytics/Analytics'
import { LoadingStateFactory } from './states/LoadingStateFactory'
import { NewCodeStateFacrory } from './states/NewCodeStateFacrory'

import { ContentGatingType } from '../../../api/types/bffTypes'
import { ActivationModalData } from './Activation'

export default class ContentGatingActivation extends OnboardingActivation {
  _rejectCallback: () => void
  override _resolveCallback: () => void
  override _titleTextKey = 'content_gating_activation_title'
  override _routerBackDisabled = false
  private _type = ContentGatingType.SOFT
  private _shouldContinueWatching = false

  override _active() {
    // Closing modal does not destroy it, keeping prev state, so need to reset
    this._shouldContinueWatching = false
  }

  override _closeCallback = () => {
    if (this._type === ContentGatingType.SOFT && this._shouldContinueWatching) {
      return this._resolveCallback()
    }
    return this._rejectCallback()
  }

  override _setModalData(modalData: ActivationModalData): void {
    if (modalData.type) {
      this._type = modalData.type
      this._setCancelButtonTranslation()
    }
    if (modalData.rejectCallback) this._rejectCallback = modalData.rejectCallback
    if (modalData.resolveCallback) this._resolveCallback = modalData.resolveCallback
    modalData.closeCallback = this._closeCallback

    super._setModalData(modalData)
  }

  private _setCancelButtonTranslation(): void {
    this.tag('Content.CancelButton')?.patch({
      label: Language.translate(
        this._type === ContentGatingType.SOFT ? 'maybe-later' : 'no-thanks'
      ).toUpperCase(),
    })
  }

  override _setTranslation() {
    super._setTranslation()
    this._setCancelButtonTranslation()
  }

  static override _states() {
    return [
      LoadingStateFactory(this),
      NewCodeStateFacrory(this),
      class Cancel extends this {
        override _getFocused() {
          return this.tag('CancelButton')
        }
        override _handleEnter(): void {
          sendMetric(EVENTS.CLICK, {
            name: this._type === ContentGatingType.SOFT ? 'Maybe Later' : 'No Thanks',
          })
          if (this._type === ContentGatingType.SOFT) {
            this._shouldContinueWatching = true
          }
          super._handleBack()
        }
        override _handleUp() {
          this._setState('NewCode')
        }
      },
    ]
  }
}
