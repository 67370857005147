import { Language } from '@lightningjs/sdk'
import { PlayerError } from './PlayerError'
import { IErrorViewModel } from './ErrorViewModel'
import AuthenticationSingleton from '../../authentication/Authentication'
import { AdobePathError, AdobePathErrorMappings } from '../../helpers'
import { ADOBE_PATH_GEO_ERROR } from '../../constants'

export default class ErrorFactory {
  _adobePassErrorMappings: AdobePathErrorMappings
  constructor(mvpdData = AuthenticationSingleton.getMvpdData()) {
    this._adobePassErrorMappings = mvpdData ? mvpdData.adobePassErrorMappings : {}
  }

  getError(e: any, errorEvent: any) {
    switch (e) {
      case PlayerError.GEO:
        if (errorEvent?.code && Number(errorEvent.code) === 345) {
          return this._createErrorViewModel(ADOBE_PATH_GEO_ERROR, false)
        }
        return {
          title: Language.translate('errors_geoTitle'),
          message: errorEvent?.description || Language.translate('errors_geoMessage'),
          retryable: false,
        }
      case PlayerError.AUTHZ:
        if (errorEvent?.details || errorEvent?.data?.details) {
          return {
            title: Language.translate('errors_retransTitle'),
            message: errorEvent?.details || errorEvent?.data?.details,
            retryable: false,
          }
        } else if (errorEvent?.code === 345) {
          // @See PL-709 this comes through as authz with nothing other
          // than code to identify - it's actually the retrans error.
          // Happens when user doesn't have access to a channel (brand).
          return {
            title: Language.translate('errors_retransTitle'),
            message: Language.translate('errors_retransMessage'),
            retryable: false,
          }
        } else {
          return this._createErrorViewModel('User Not Authorized Error')
        }
      case PlayerError.RETRANS:
        return {
          title: Language.translate('errors_retransTitle'),
          message: Language.translate('errors_retransMessage'),
          retryable: false,
        }
      case PlayerError.EXPIRED: {
        return {
          title: Language.translate('errors_expiredTitle'),
          message: Language.translate('errors_expiredMessage'),
          retryable: false,
        }
      }
      case PlayerError.PARENTAL_CONTROLS: {
        return {
          title: Language.translate('errors_parentalTitle'),
          message: Language.translate('errors_parentalMessage'),
          retryable: false,
        }
      }
      case PlayerError.SLE_ENDED:
        return {
          title: Language.translate('sle-empty-title'),
          message: Language.translate('sle-empty-subtitle'),
          retryable: false,
          buttonLabel: Language.translate('sle-full-schedule-button'),
          redirectable: true,
        }
      case PlayerError.VSF_3016:
        return {
          title: '',
          message: Language.translate('vsf-3016-error'),
          retryable: false,
        }
      default:
        return this._createErrorViewModel('Default Error')
    }
  }

  getErrorType(e: any) {
    switch (e) {
      case PlayerError.GEO:
        return 'Geo'
      case PlayerError.AUTHZ:
        return 'Auth'
      case PlayerError.MISSING_LIVE_DATA:
        return 'Video'
      case PlayerError.PARENTAL_CONTROLS:
        return 'Parental_Controls'
      case PlayerError.UNKNOWN:
        return 'Other'
      case PlayerError.EXPIRED:
        return 'Expired'
    }
  }

  _createErrorViewModel(key: any, retryable = false) {
    let cmsErrorObject: AdobePathError | undefined = this._adobePassErrorMappings[key]
    // FIXME: If the user is not signed in the adobe error map is not retrieved.
    if (!cmsErrorObject) {
      cmsErrorObject = {
        title: "Sorry! We're having some trouble...",
        body: 'If the problem persists, please go to More -> Contact Us and send us a note.',
      }
    }
    return new IErrorViewModel(
      Language.translate(cmsErrorObject.title),
      Language.translate(cmsErrorObject.body),
      retryable
    )
  }
}
