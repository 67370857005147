import { Language, Log, Storage } from '@lightningjs/sdk'

import Preferences from '../Preferences'
import { COLORS, STORAGE_KEYS } from '../../constants'
import { StorageFactorySingleton } from '../../util/storage/StorageFactory'
import { convertHexToRgb } from '../../helpers'

export enum CCTypes {
  off = 'off',
  en = 'en',
  es = 'es',
}

export enum CCFonts {
  default = 'Default',
  'proportional-sans-serif' = 'Arial',
  'monospaced-sans-serif' = 'Helvetica',
  cursive = 'Coronet',
  'proportional-serif' = 'TimesNewRoman',
  'monospaced-serif' = 'Courier',
  casual = 'Impress',
  'small-capitals' = 'Copperplate',
}

/**
 * Prefixes for opacity for hex colors in format 0x...
 */
export enum CCOpacities {
  '100%' = 'ff',
  '75%' = 'bf',
  '50%' = '80',
  '25%' = '40',
  '0%' = '00',
}

// Using numbers as enum values compile as a bidirectional object, which we don't want
export const CCFontSizes = Object.freeze({
  large: 56,
  medium: 45,
  small: 33,
})
export type CCFontSizes = (typeof CCFontSizes)[keyof typeof CCFontSizes]

export enum CCColors {
  black = 'Black',
  blue = 'Blue',
  green = 'Green',
  magenta = 'Magenta',
  cyan = 'Cyan',
  teal = 'Teal',
  red = 'Red',
  purple = 'Purple',
  yellow = 'Yellow',
  white = 'White',
}

export enum CCEdgeStyles {
  none = 'none',
  uniform = 'uniform',
  raised = 'raised',
  shadow = 'shadow',
  depressed = 'depressed',
}

const defaultValues = Object.freeze({
  [Preferences.CLOSED_CAPTION_FONT_STYLE]: CCFonts.default,
  [Preferences.CLOSED_CAPTION_FONT_SIZE]: CCFontSizes.medium,
  [Preferences.CLOSED_CAPTION_FONT_COLOR]: CCColors.white,
  [Preferences.CLOSED_CAPTION_FONT_OPACITY]: CCOpacities['100%'],
  [Preferences.CLOSED_CAPTION_BACKGROUND_COLOR]: CCColors.black,
  [Preferences.CLOSED_CAPTION_BACKGROUND_OPACITY]: CCOpacities['100%'],
  [Preferences.CLOSED_CAPTION_WINDOW_COLOR]: CCColors.black,
  [Preferences.CLOSED_CAPTION_WINDOW_OPACITY]: CCOpacities['0%'],
  [Preferences.CLOSED_CAPTION_EDGE_STYLE]: CCEdgeStyles.none,
  [Preferences.CLOSED_CAPTION_EDGE_COLOR]: CCColors.white,
  [Preferences.CLOSED_CAPTION_EDGE_OPACITY]: CCOpacities['100%'],
})

const LAST_KNOWN_CC_DEVICE_SETTING = 'lastKnownCcDeviceSetting'

export const syncCcSettings = (enabled: any) => {
  const storeSvc = StorageFactorySingleton.get()
  const lastKnownSetting = storeSvc.get(LAST_KNOWN_CC_DEVICE_SETTING)

  Log.info(
    `Device CC: ${enabled}.${
      lastKnownSetting !== undefined ? ' Last known device CC value: ' + lastKnownSetting : ''
    }`
  )
  if (enabled !== lastKnownSetting) {
    storeSvc.set(LAST_KNOWN_CC_DEVICE_SETTING, enabled)
    const ccType = enabled ? Storage.get(STORAGE_KEYS.APP_LANGUAGE) || CCTypes.en : CCTypes.off
    ClosedCaptionsUtils.setCCType(ccType)
  }
}

export const getClosestColorFromHex = (hexColor: any) => {
  if (!/#/.test(hexColor)) return hexColor

  const rgbMaps = {
    black: [0, 0, 0],
    blue: [0, 0, 255],
    green: [0, 255, 0],
    teal: [0, 128, 128],
    magenta: [255, 0, 255],
    cyan: [0, 255, 255],
    red: [255, 0, 0],
    purple: [128, 0, 128],
    yellow: [255, 255, 0],
    white: [255, 255, 255],
  }

  const rgbInput = convertHexToRgb(hexColor)

  const key = Object.entries(rgbMaps).reduce((acc, cur) => {
    const getDistance = (dx: number, dy: number, dz: number) => {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      dx = dx - rgbInput[0]
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      dy = dy - rgbInput[1]
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      dz = dz - rgbInput[2]
      return Math.sqrt(dx * dx + dy * dy + dz * dz)
    }
    // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
    return getDistance(...cur[1]) < getDistance(...acc[1]) ? cur : acc
  })?.[0]

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return CCColors[key] || CCColors.black
}

export class ClosedCaptionsUtils {
  static values = {
    [Preferences.CLOSED_CAPTION_FONT_STYLE]: {
      label: 'font-style',
      values: CCFonts,
    },
    [Preferences.CLOSED_CAPTION_FONT_OPACITY]: {
      label: 'font-opacity',
      values: CCOpacities,
    },
    [Preferences.CLOSED_CAPTION_FONT_SIZE]: {
      label: 'font-size',
      values: CCFontSizes,
    },
    [Preferences.CLOSED_CAPTION_FONT_COLOR]: {
      label: 'font-color',
      values: CCColors,
    },
    [Preferences.CLOSED_CAPTION_BACKGROUND_OPACITY]: {
      label: 'background_opacity',
      values: CCOpacities,
    },
    [Preferences.CLOSED_CAPTION_BACKGROUND_COLOR]: {
      label: 'background-color',
      values: CCColors,
    },
    [Preferences.CLOSED_CAPTION_WINDOW_OPACITY]: {
      label: 'window-opacity',
      values: CCOpacities,
    },
    [Preferences.CLOSED_CAPTION_WINDOW_COLOR]: {
      label: 'window-color',
      values: CCColors,
    },
    [Preferences.CLOSED_CAPTION_EDGE_OPACITY]: {
      label: 'edge-opacity',
      values: CCOpacities,
    },
    [Preferences.CLOSED_CAPTION_EDGE_STYLE]: {
      label: 'edge-style',
      values: CCEdgeStyles,
    },
    [Preferences.CLOSED_CAPTION_EDGE_COLOR]: {
      label: 'edge-color',
      values: CCColors,
    },
  }

  static getColorHex(color: string | CCColors, opacity?: string): number {
    const composeOpacity = (v: string) => Number(`0x${opacity}${v.replace('ff', '')}`)
    const capitalized = color[0]?.toUpperCase() + color.slice(1)
    const value =
      {
        [CCColors.black]: COLORS.black,
        [CCColors.blue]: COLORS.blue1,
        [CCColors.green]: COLORS.green,
        [CCColors.purple]: COLORS.purple,
        [CCColors.magenta]: COLORS.magenta,
        [CCColors.cyan]: COLORS.cyan,
        [CCColors.red]: COLORS.red1,
        [CCColors.teal]: COLORS.teal,
        [CCColors.white]: COLORS.white,
        [CCColors.yellow]: COLORS.yellow,
      }[capitalized] || COLORS.black
    return opacity ? composeOpacity(value.toString(16)) : value
  }

  static getCCTypeLabel(ccType?: CCTypes) {
    switch (ccType) {
      case CCTypes.en:
        return Language.translate('english')
      case CCTypes.es:
        return Language.translate('spanish')
      case CCTypes.off:
      default:
        return Language.translate('off')
    }
  }

  static getAnalyticsCCLanguage(ccType?: CCTypes): string | void {
    if (!ccType || ccType === CCTypes.off) return
    return this.getCCTypeLabel(ccType)
  }

  static getCCType(): CCTypes {
    return Preferences.get(Preferences.CLOSED_CAPTION) || CCTypes.off
  }

  static setCCType(language: any) {
    Preferences.store(Preferences.CLOSED_CAPTION, language)
  }

  static getLabel(key: any) {
    const value = this.values[key]?.label
    return Language.translate(value) || value || key
  }

  static getFallbackCCType(type: CCTypes) {
    if (type === CCTypes.off) return
    return type === CCTypes.en ? CCTypes.es : CCTypes.en
  }

  static getPossibleValuesForKey(key: Preferences): { label: string; value: string }[] | undefined {
    // @ts-expect-error TS(2769): No overload matches this call.
    return Object.entries(this.values[key]?.values)?.map(([label, value]) => ({
      label,
      value,
    }))
  }

  /**
   * Set a value for a given Preferences key
   * @param {*} key A Preferences constant
   * @param {*} value A valid value for that Preferences key
   * @returns {boolean}
   */
  static setValue(key: any, value: any) {
    const acceptedValues = Object.values(this.values[key]?.values || {})
    const validOption = acceptedValues.includes(value)
    if (validOption) {
      Preferences.store(key, value)
      return true
    }
    Log.warn(
      `Value ${value} is not valid for key ${key}. Accepted values: ${acceptedValues.join(',')}`
    )
    return false
  }

  static getValue(key: any) {
    return Preferences.get(key) || defaultValues[key]
  }

  static getDisplayLabelForOption(key: any) {
    let value = this.getValue(key)
    const values: { [key: string]: string | number } | undefined = this.values[key]?.values

    if (
      [
        Preferences.CLOSED_CAPTION_BACKGROUND_OPACITY,
        Preferences.CLOSED_CAPTION_EDGE_OPACITY,
        Preferences.CLOSED_CAPTION_FONT_OPACITY,
        Preferences.CLOSED_CAPTION_WINDOW_OPACITY,
        Preferences.CLOSED_CAPTION_FONT_SIZE,
        Preferences.CLOSED_CAPTION_FONT_STYLE,
        Preferences.CLOSED_CAPTION_FONT_COLOR,
        Preferences.CLOSED_CAPTION_BACKGROUND_COLOR,
      ].includes(key)
    ) {
      if (values) {
        value = Object.keys(values).find((item) => values[item] === value)
        if (!value) {
          this.setValue(key, defaultValues[key])
          value = Object.keys(values).find((option) => values[option] === defaultValues[key])
        }
      }
    }

    return Language.translate(value) || value
  }

  static resetSettings() {
    Object.keys(this.values).forEach((preference) => Preferences.remove(preference))
  }
}
