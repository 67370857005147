import { Log } from '@lightningjs/sdk'
import { PlayerEventEmitter } from './PlayerEventEmitter'
import {
  AudioTrackEvent,
  AudioTracksEvent,
  BingeEndcardEvent,
  FatalErrorEvent,
  MutablePlayerEventStream,
  PlayerStatusEvent,
  SeekEndEvent,
  SeekStartEvent,
  SubtitleEvent,
  TimeChangeEvent,
  TrickPlayImageEvent,
} from '../event'
import { PlayerStatus } from '../PlayerStatus'
import {
  PlaybackTimeline,
  SessionController,
  SessionState,
  SubtitleCue,
  Track,
} from '@sky-uk-ott/core-video-sdk-js-core'
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore'
import { executeAfterJitterDelay } from '../../../util/jitter'
import AppConfigFactorySingleton from '../../../config/AppConfigFactory'
import { END_CARD_FER_TRIGGER_TIME } from '../../../constants'
import { isBlockingModalOpen } from '../../../lib/ModalManager'

const CORE_EMITTER_TAG = 'Core Video Emitter'

export type CoreEmitterEvents =
  | typeof PlayerStatusEvent
  | typeof FatalErrorEvent
  | typeof SubtitleEvent
  | typeof TimeChangeEvent
  | typeof SeekStartEvent
  | typeof SeekEndEvent
  | typeof TrickPlayImageEvent
  | typeof BingeEndcardEvent
  | typeof AudioTracksEvent
  | typeof AudioTrackEvent

export class CorePlayerStatusEmitter extends PlayerEventEmitter {
  private _ferBingeAPICall: boolean
  private _programDuration: any
  private _triggerTimeInSeconds: any
  private _program: any

  _allowedEvents: CoreEmitterEvents[] = [
    PlayerStatusEvent,
    FatalErrorEvent,
    SubtitleEvent,
    TimeChangeEvent,
    SeekStartEvent,
    SeekEndEvent,
    TrickPlayImageEvent,
    BingeEndcardEvent,
    AudioTracksEvent,
    AudioTrackEvent,
  ]

  constructor(allowedEvents?: CoreEmitterEvents[]) {
    super()
    if (allowedEvents) this._allowedEvents = allowedEvents

    const { program } = PlayerStoreSingleton
    this._program = program
    this._programDuration = program?.duration || 0
    this._triggerTimeInSeconds = this._programDuration - END_CARD_FER_TRIGGER_TIME

    this._ferBingeAPICall = false
  }

  override attach(player: SessionController, mutablePlayerEventStream: MutablePlayerEventStream) {
    super.attach(player, mutablePlayerEventStream)
    if (!player) {
      Log.warn(`${CORE_EMITTER_TAG} unable to attach core player emitter`)
      return
    }
    if (this._allowedEvents.includes(PlayerStatusEvent)) {
      player.onStateChanged?.(this._onStateChanged)
      player.onPlayoutDataReceived(this._onManifestParsed)
    }
    if (this._allowedEvents.includes(TrickPlayImageEvent)) {
      player.onAvailableThumbnailVariantsChanged?.(this._onAvailableThumbnailVariantsChanged)
    }
    if (this._allowedEvents.includes(TimeChangeEvent)) {
      if (player.onPlaybackTimelineUpdated) {
        player.onPlaybackTimelineUpdated(this._onPlaybackTimelineUpdated)
      } else {
        Log.warn(`${CORE_EMITTER_TAG} onPlaybackTimelineUpdated not found`)
      }
    }
    if (this._allowedEvents.includes(FatalErrorEvent)) player.onError?.(this._onError)
    if (this._allowedEvents.includes(SubtitleEvent))
      player.onAvailableSubtitlesTracksChanged?.(this._onAvailableSubtitlesTracksChanged)
    if (this._allowedEvents.includes(SubtitleEvent))
      player.onSubtitleCuesChanged?.(this._onSubtitleCuesChanged)
    if (this._allowedEvents.includes(SeekStartEvent)) player.onSeekStarted?.(this._onSeekStarted)
    if (this._allowedEvents.includes(SeekEndEvent)) player.onSeekEnded?.(this._onSeekEnded)
    if (this._allowedEvents.includes(BingeEndcardEvent))
      player.onEndOfEventMarkerReceived?.(this._onEndOfEventMarkerReceived)
    if (this._allowedEvents.includes(AudioTracksEvent))
      player.onAvailableAudioTracksChanged?.(this._onAudioTracksChanged)
    if (this._allowedEvents.includes(AudioTrackEvent))
      player.onAudioTrackChanged?.(this._onAudioTrackChanged)
  }

  //#region Private
  _onSeekStarted = () => {
    this._normalizedPlayerEvents?.publish(new SeekStartEvent({}))
  }

  _onSeekEnded = () => {
    this._normalizedPlayerEvents?.publish(new SeekEndEvent({}))
  }

  _onAvailableThumbnailVariantsChanged = async (thumbnailVariants: any) => {
    this._normalizedPlayerEvents?.publish(new TrickPlayImageEvent(thumbnailVariants))
  }

  _onEndOfEventMarkerReceived = () => {
    const jitterConfig = AppConfigFactorySingleton.config?.binge?.jitter
    const sleBingeJitterMin = jitterConfig?.min || 0
    const sleBingeJitterMax = jitterConfig?.max || 0
    // usage when a Binge Marker is received
    executeAfterJitterDelay(
      () => {
        // Logic to request Binge Tiles from BFF
        if (!isBlockingModalOpen()) { // do not show Binge end cards when activation modal is displayed
          this._normalizedPlayerEvents?.publish(new BingeEndcardEvent())
        }
      },
      sleBingeJitterMin,
      sleBingeJitterMax
    )
  }

  _onFEREndOfEventMarkerReceived = () => {
    this._normalizedPlayerEvents?.publish(new BingeEndcardEvent())
  }

  _onError = (error: any) => {
    if (error?.severity?.toLowerCase?.() === 'warning') {
      Log.warn(`${CORE_EMITTER_TAG} ${JSON.stringify(error)}`)
    } else {
      this._normalizedPlayerEvents?.publish(
        new FatalErrorEvent({
          fatal: true,
          description: error.message ?? 'Fatal player error',
          code: error.code ?? '404',
          category: error.category,
        })
      )
    }
  }

  _onManifestParsed = () => {
    this._normalizedPlayerEvents?.publish(new PlayerStatusEvent(PlayerStatus.READY, {}))
  }

  _onStateChanged = (playerState: SessionState) => {
    switch (playerState) {
      case SessionState.Loading:
        this._normalizedPlayerEvents?.publish(new PlayerStatusEvent(PlayerStatus.LOADING, {}))
        break
      case SessionState.Rebuffering:
        this._normalizedPlayerEvents?.publish(new PlayerStatusEvent(PlayerStatus.BUFFERING, {}))
        break
      case SessionState.Paused:
        this._normalizedPlayerEvents?.publish(new PlayerStatusEvent(PlayerStatus.PAUSED, {}))
        break
      case SessionState.Playing:
        this._normalizedPlayerEvents?.publish(new PlayerStatusEvent(PlayerStatus.PLAYING, {}))
        break
      case SessionState.Seeking:
        this._normalizedPlayerEvents?.publish(new PlayerStatusEvent(PlayerStatus.SEEKING, {}))
        break
      case SessionState.Finished:
        this._normalizedPlayerEvents?.publish(new PlayerStatusEvent(PlayerStatus.FINISHED, {}))
        break
      default:
        break
    }
  }

  _onAvailableSubtitlesTracksChanged = (tracks: Track[]) => {
    this._normalizedPlayerEvents?.publish(new SubtitleEvent({ tracks }))
  }

  _onSubtitleCuesChanged = (cues: SubtitleCue[]) => {
    this._normalizedPlayerEvents?.publish(new SubtitleEvent({ cues }))
  }

  _onPlaybackTimelineUpdated = (timeline: PlaybackTimeline) => {
    const { position, seekableRange, isAtLiveEdge } = timeline
    if (position) {
      this._normalizedPlayerEvents?.publish(
        new TimeChangeEvent(position, seekableRange, isAtLiveEdge)
      )
    }

    if (
      this._program?.programmingType === 'Full Event Replay' &&
      position >= this._triggerTimeInSeconds &&
      !this._ferBingeAPICall
    ) {
      this._onFEREndOfEventMarkerReceived()
      this._ferBingeAPICall = true
    }
  }

  _onAudioTracksChanged = (data: Track[]) => {
    this._normalizedPlayerEvents?.publish(new AudioTracksEvent(data))
  }

  _onAudioTrackChanged = (track: Track['id']) => {
    this._normalizedPlayerEvents?.publish(new AudioTrackEvent(track))
  }
  //#endregion
}
