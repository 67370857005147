import { Lightning, Router, Language } from '@lightningjs/sdk'

import BaseComponent from '../base'
import {
  tertiaryTitleDecorator,
  clipCardDecorator,
  badgeDecorator,
  liveProgressBarDecorator,
  durationDecorator,
} from './decorators'
import { lockDecorator } from '../items/decorators'

import { ALGOLIA_ENTITY_TYPES, FastImg, formatSecondsAsHhMmSSForTTS } from '../../helpers'
import { navigateUpcomingLiveItems } from '../items/helpers/upcomingItems'
import { BADGE_LABELS, COLORS, FONT_FACE, OLY_IMPOLICY } from '../../constants'

export default class PagingListTile extends BaseComponent {
  _item: any
  fire: any
  static override _template() {
    return {
      color: 'transparent',
      rect: true,
      clipping: true,
      Focus: {
        alpha: 0,
        rect: true,
        color: 'transparent',
        w: (w: any) => w,
        h: (h: any) => h,
        shader: {
          type: Lightning.shaders.Outline,
          width: 6,
        },
      },
      LazyImageFallback: {
        x: 13,
        y: 13,
        w: 460,
        h: 259,
        rect: true,
        colorLeft: COLORS.brightGrey,
        colorRight: COLORS.thunder2,
      },
      Image: {
        x: 13,
        y: 13,
        w: 460,
        h: 259,
      },
      Title: {
        x: 508,
        y: 17,
        text: {
          fontFace: FONT_FACE.regular,
          fontSize: 36,
          maxLines: 1,
        },
      },
      SubTitle: {
        w: 850,
        x: 508,
        color: COLORS.white,
        text: {
          fontFace: FONT_FACE.light,
          fontSize: 30,
          maxLines: 3,
          fontWeight: 300,
          lineHeight: 40,
        },
      },
      TertiaryTitle: {
        y: 116,
        x: 508,
        text: {
          fontFace: FONT_FACE.light,
          fontSize: 30,
          maxLines: 3,
          fontWeight: 300,
          lineHeight: 40,
        },
      },
      Description: {
        w: 850,
        x: 508,
        y: 165,
        color: COLORS.white,

        text: {
          fontFace: FONT_FACE.light,
          fontSize: 30,
          maxLines: 2,
          fontWeight: 300,
          lineHeight: 40,
        },
      },
      BrandLogo: {
        mount: 1,
        x: 1426 + 50,
        y: 195 + 40,
      },
    }
  }

  override _init() {
    super._init()
    this._addDecorators([
      tertiaryTitleDecorator,
      clipCardDecorator,
      badgeDecorator(this._item?.video?.labelBadge || this.item?.data?.labelBadge),
      lockDecorator,
      liveProgressBarDecorator,
      durationDecorator,
    ])
  }

  set item(v) {
    this._item = v

    this.patch({
      Image: {
        texture: FastImg(this.image).contain(460, 259, OLY_IMPOLICY.RECT_SMEDIUM_640_360),
      },
      Title: {
        text: {
          text: this.title,
        },
      },
      SubTitle: {
        y: 92,
        text: {
          text: this.subTitle,
        },
      },
      BrandLogo: {
        texture: FastImg(this.brandLogo).contain(52, 30, OLY_IMPOLICY.RECT_SMALL_384_216),
      },
    })
  }

  get item() {
    return this._item
  }

  get showTertiaryTitle() {
    return (
      this.entityType === ALGOLIA_ENTITY_TYPES.EPISODES ||
      this.entityType === ALGOLIA_ENTITY_TYPES.REPLAYS ||
      this.entityType === ALGOLIA_ENTITY_TYPES.SLE ||
      this.entityType === ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS
    )
  }

  get showClipData() {
    return this.item.algoliaProperties?.isSegment || this.item.algoliaProperties?.isMovieSegment
  }

  get image() {
    return this.item?.data?.image?.path
  }

  get title() {
    const { data } = this.item
    let title = ''

    switch (this.entityType) {
      case ALGOLIA_ENTITY_TYPES.SEGMENTS:
      case ALGOLIA_ENTITY_TYPES.EPISODES:
        title = data.series.title
        break
      case ALGOLIA_ENTITY_TYPES.MOVIES:
        title = data.shortDisplayTitle
        break
      case ALGOLIA_ENTITY_TYPES.SERIES:
      case ALGOLIA_ENTITY_TYPES.SPORTS_SERIES:
        title = data.title
        break
      case ALGOLIA_ENTITY_TYPES.REPLAYS:
      case ALGOLIA_ENTITY_TYPES.SLE:
      case ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS:
        title = data.series?.title || data.league.title || data.sport.title
        break
      default:
    }

    return title
  }

  get subTitle() {
    const { data } = this.item
    let description = ''

    switch (this.entityType) {
      case ALGOLIA_ENTITY_TYPES.EPISODES:
        description = data.tertiaryTitle
        break
      case ALGOLIA_ENTITY_TYPES.SERIES:
      case ALGOLIA_ENTITY_TYPES.MOVIES:
      case ALGOLIA_ENTITY_TYPES.SPORTS_SERIES:
        description = data.longDescription
        break
      case ALGOLIA_ENTITY_TYPES.REPLAYS:
      case ALGOLIA_ENTITY_TYPES.SLE:
      case ALGOLIA_ENTITY_TYPES.SEGMENTS:
        description = data.title
        break
      case ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS:
        description = data.subTitle
        break
      default:
    }

    return description
  }

  get announce() {
    const announcements = []
    // Line 1
    if (this.item?.video?.labelBadge) {
      announcements.push(this.item.video.labelBadge)
    }
    announcements.push(this.title)

    // Line 2
    if (
      [
        ALGOLIA_ENTITY_TYPES.SLE,
        ALGOLIA_ENTITY_TYPES.REPLAYS,
        ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS,
      ].includes(this.entityType)
    ) {
      announcements.push(this.subTitle)
    }

    // line 3
    if ([ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS].includes(this.entityType)) {
      announcements.push(`${Language.translate('aired_on')} ${this.tertiaryTitle}`)
    }

    // line 4
    if (
      [ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS].includes(this.entityType) &&
      this.item.video?.duration
    ) {
      announcements.push(`${formatSecondsAsHhMmSSForTTS(this.item.video?.duration)}`)
    }

    return announcements
  }

  get description() {
    const { data } = this.item

    return data.shortDescription || data.description || ''
  }

  get brandLogo() {
    const { data } = this.item

    return data.brand.whiteBrandLogo.path
  }

  get entityType() {
    return this.item?.algoliaProperties?.entityType
  }

  get tertiaryTitle() {
    const { data } = this.item
    if (this.entityType === ALGOLIA_ENTITY_TYPES.EPISODES) return data.title
    if (this.entityType === ALGOLIA_ENTITY_TYPES.REPLAYS) return data.tertiaryTitle
    return data.tertiaryTitle
  }

  _getBadgeLabel(label: any) {
    // Only return translations we have a known value for.
    // Otherwise the translate function would return labelBadge-UNKNOWN_LABEL
    // With this protection we just return the label in English which is still better.
    if (!BADGE_LABELS.includes(label)) return label
    return Language.translate(`labelBadge-${label.toLowerCase()}`)
  }

  override _focus() {
    this.fire('toggleFocus', { v: true })
  }

  override _unfocus() {
    this.fire('toggleFocus', { v: false })
  }

  toggleFocus({ v }: any) {
    this.tag('Focus').patch({
      alpha: v ? 1 : 0,
    })
  }

  _getUpcomingItemObject() {
    return {
      startTime: this.item.data.startDate,
      data: {
        pid: this.item.data.pid,
      },
      isLive: this.item.data.isLive,
      channelId: this.item.data.channelId,
      customerPlayableDate: this.item.data.customerPlayableDate,
      upcomingModal: this.item.data.upcomingModal,
    }
  }

  override _handleEnter() {
    let route
    // @ts-expect-error TODO type this signal adding a LightningJS typeconfig
    this.signal('onClickItem', this.item)
    switch (this.entityType) {
      case ALGOLIA_ENTITY_TYPES.EPISODES:
      case ALGOLIA_ENTITY_TYPES.SEGMENTS:
      case ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS:
        route = `video/${this.item.video.mpxGuid}`
        break
      case ALGOLIA_ENTITY_TYPES.SERIES:
      case ALGOLIA_ENTITY_TYPES.SPORTS_SERIES:
        route = `show/${this.item.data.urlAlias}`
        break
      case ALGOLIA_ENTITY_TYPES.MOVIES:
        route = `movie/${this.item.data.urlAlias}`
        break
      case ALGOLIA_ENTITY_TYPES.REPLAYS:
        route = `watch/${this.item.data.pid}`
        break
      case ALGOLIA_ENTITY_TYPES.SLE:
        if (this.item.data.isUpcoming) {
          navigateUpcomingLiveItems({
            item: this._getUpcomingItemObject(),
            fireAncestors: this.fireAncestors.bind(this),
            preventClickMetric: true,
          })
        } else {
          route = this.item.data.isLive ? `watch/${this.item.data.pid}` : ''
        }

        break
      default:
        route = ''
    }

    if (route) {
      Router.navigate(route, { keepAlive: true, preventClickMetric: true })
    }
  }

  get isLocked() {
    return this.item?.video?.authAccess?.ConnectedTvDevice || this.item.data.locked
  }

  get lockContainer() {
    return this.tag('Image')
  }

  get keyBadgePosition() {
    return 460
  }
}
