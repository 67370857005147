import { isFullEpisode, setDefaultValue } from '../../../../helpers'
import { getStreamType } from './getLinearAttributes'
import {
  MPARTICLE_DEFAULT_ATTR_VALUE,
  MPARTICLE_ERROR_CONTEXTS,
  ENTITLEMENT,
} from '../../../../constants'

const getErrorContext = (source = '') => {
  switch (source) {
    case MPARTICLE_ERROR_CONTEXTS.vod:
      return 'Player; Video Player.'
    case MPARTICLE_ERROR_CONTEXTS.live:
      return 'Player; Live Player.'
    default:
      return MPARTICLE_DEFAULT_ATTR_VALUE
  }
}

const getVodAttributes = (video: any) => {
  const {
    series,
    seasonNumber,
    title,
    episodeNumber,
    mpxGuid,
    pid,
    locked,
    programmingType,
    brandDisplayTitle,
    seriesShortTitle,
    secondaryTitle,
  } = video
  return {
    Show: series || seriesShortTitle,
    Season: seasonNumber,
    'Episode Title': isFullEpisode(programmingType) ? secondaryTitle : title,
    'Episode Number': episodeNumber,
    'Video ID': mpxGuid || pid,
    'Video Type': programmingType,
    Brand: brandDisplayTitle,
    Entitlement: locked ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE,
    'Stream Type': MPARTICLE_DEFAULT_ATTR_VALUE,
  }
}

const getLiveAttributes = (video: any) => {
  const {
    showName,
    seasonNumber,
    title,
    episodeNumber,
    v4ID,
    pid,
    programmingType,
    type,
    xyFallback,
  } = video
  const { brandDisplayTitle, entitlement } = video
  return {
    Show: showName,
    Season: seasonNumber,
    'Episode Title': title,
    'Episode Number': episodeNumber,
    'Video ID': pid || v4ID, // Linear doesn't have PID but SLE has v4ID
    'Video Type': type || programmingType,
    Brand: brandDisplayTitle,
    Entitlement: entitlement,
    'Stream Type': getStreamType(xyFallback),
  }
}

const getVideoAttributes = (video: any = {}, player: any = {}, isLive = null) => {
  if (!video) return {}
  // Pass duration and progress in milliseconds
  const length = video?.durationInMilliseconds || video.duration * 1000 || 0
  const durationWatched = player?.lastPosition ? player.lastPosition * 1000 : 0
  return {
    ...(isLive ? getLiveAttributes(video) : getVodAttributes(video)),
    'Video Duration': length,
    'Duration Watched': durationWatched,
  }
}

const getErrorAttributes = (params: any) => {
  const {
    errorType,
    system,
    stack,
    description,
    friendlyMessage,
    mvpd,
    video,
    code,
    source,
    player,
    isLive,
  } = params
  return setDefaultValue(
    {
      ...getVideoAttributes(video, player, isLive),
      'Error Type': errorType,
      'Error System': system,
      'Error MVPD': mvpd?.mvpd || 'Unauthenticated',
      'Error Expected': 'False',
      'Error Fatal': 'True',
      'Error Sub Type': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Error Feature Type': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Error Omniture Code': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Error Source Error Code': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Error Source Type': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Error Stack': stack,
      'Error Context': getErrorContext(source),
      'Error Friendly Message': friendlyMessage,
      'Error Description': description,
      'Error Code': code,
    },
    MPARTICLE_DEFAULT_ATTR_VALUE
  )
}

export default getErrorAttributes
