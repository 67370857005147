import { Log } from '@lightningjs/sdk'
import { get } from 'lodash'
import { Subscription } from 'rxjs'
import {
  CoreVideoSdk,
  LicenseRequest,
  PlayerController,
  SessionController,
  SessionState,
  SubtitleCue,
  Track,
  VideoPlatform,
} from '@sky-uk-ott/core-video-sdk-js'
import type { Device } from '@sky-uk-ott/client-lib-js-device'
import { loadDeviceFactory } from '@sky-uk-ott/client-lib-js-device'
import { PlayerInterface, PlayerMode, PlayerOptions, PlayerState } from '../core/PlayerInterface'
import {
  getCoreStreamUrl,
  getCoreVideoSdkConfig,
  getPlayoutData,
  getSessionConfig,
  PlayerData,
} from './getCoreVideoSdkConfig'
import AppConfigFactorySingleton from '../../config/AppConfigFactory'
import { PlayerStatus } from '../model/PlayerStatus'
import {
  ErrorEvent,
  FatalErrorEvent,
  LoadSourceEvent,
  PlatcoPlayerEvents,
  PlayerStatusEvent,
  SubtitleEvent,
} from '../model/event'
import { PlayerError } from '../model/PlayerError'
import { CCTypes, ClosedCaptionsUtils } from '../../lib/ClosedCaptions/ClosedCaptionsUtils'
import {
  AvailableEmitters,
  PlayerEventEmitterRegistry,
  PlayerEventSetup,
} from '../model/emitter/PlayerEventEmitterRegistry'
import {
  getMpid,
  getProduct,
  isNBCProfileLinked,
  isProduction,
  isXbox,
  isXclass,
} from '../../helpers'
import { CoreVideoAdReporter } from '../model/emitter/CoreVideoAdReporter'
import PlayerStoreSingleton from '../../store/PlayerStore/PlayerStore'
import { AAMPCCDelegate } from './delegates/CC/AAMPCCDelegate'
import AuthenticationSingleton from '../../authentication/Authentication'
import { CCStyleEvent } from '../../lib/tv-platform/base'
import { BaseCCDelegate } from './delegates/CC/BaseCCDelegate'
import { PLAYER_SIZE, SCREEN_SIZE } from '../../constants'
import TVPlatform from '../../lib/tv-platform'
import { AnnouncerEvent } from '../../lib/tts/Announcer'
import { SubscriptionBuilder, SubscriptionSources } from '../../util/SubscriptionBuilder'
import { ErrorType } from '../../lib/tv-platform/types'
import { LemonadeResponse } from '../../lib/lemonade/Lemonade'
import { DebugControllerSingleton } from '../../util/debug/DebugController'

const CORE_VIDEO_TAG = 'Core Video Player'

const isSizeArray = (input: any): input is [number, number, number, number] =>
  Array.isArray(input) &&
  input.length === 4 &&
  input?.every((value: any) => typeof value === 'number')

export class CoreVideoPlayer extends PlayerInterface {
  _coreConfig?: { enableWorker: boolean }
  _override?: PlayerData

  // Core Video Controllers
  _coreVideoController: PlayerController | null = null
  _sessionController: SessionController | null = null

  // Assets
  _subtitleCues: SubtitleCue[] = []
  _subtitleTracks: Track[] = []

  // Delegates
  _ccDelegate?: BaseCCDelegate

  // Timeouts
  _mediaRecoveryTimeout: number

  // TTS Subscription
  _ttsSubscription?: Subscription

  static override RECOVER_ERROR_TIMEOUT = 10000

  constructor(playerMode: PlayerMode, options?: Partial<PlayerOptions>) {
    super(playerMode, options)
    if (isXclass()) this._ccDelegate = new AAMPCCDelegate(this)
    this._ttsSubscription = new SubscriptionBuilder()
      .with({
        type: SubscriptionSources.TTS_ANNOUNCER,
        events: [AnnouncerEvent.TTS_START],
        handler: this._startTts.bind(this),
      })
      .with({
        type: SubscriptionSources.TTS_ANNOUNCER,
        events: [AnnouncerEvent.TTS_END],
        handler: this._endTts.bind(this),
      })
      .subscribe()
  }

  get id() {
    return 'com.nbc.player.corevideoplayer'
  }

  get framework() {
    return 'Core Video'
  }

  override get version() {
    return CoreVideoSdk.version
  }

  _getPlayoutData(vod: boolean) {
    return () => getPlayoutData(vod)
  }

  loadDevice = async (): Promise<Device> => {
    const deviceFactory = await loadDeviceFactory(TVPlatform.deviceSdkConfig)
    return await deviceFactory.initialise()
  }

  async loadCoreSdk() {
    Log.info(`${CORE_VIDEO_TAG} attempt to loadSdk`)
    // Load the sdk
    const sdkConfig = getCoreVideoSdkConfig()
    Log.info(`${CORE_VIDEO_TAG} sdk config:`, sdkConfig)
    Log.info(`${CORE_VIDEO_TAG} sdk version:`, CoreVideoSdk.version)
    try {
      const device = await this.loadDevice()
      Log.info('>>> device', { device })
      await CoreVideoSdk.initialise(sdkConfig, device, {
        type: VideoPlatform.OVP,
        getVodPlayoutData: this._getPlayoutData(true), // For FER/VODs
        getPreviewPlayoutData: this._getPlayoutData(true), // For FER/VODs
        getLivePlayoutData: this._getPlayoutData(false),
        getEventPlayoutData: this._getPlayoutData(false), // For SLEs
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        sendHeartbeat: async () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        stopHeartbeat: async () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        updateBookmark: async () => {},
        validateLicenseResponse: () => true,
        decorateLicenseRequest: (licenseRequest: LicenseRequest) => licenseRequest,
      })
      Log.info(`${CORE_VIDEO_TAG} loadSdk successful`)
    } catch (err: any) {
      switch (err.message) {
        case 'Core SDK has already been initialised':
          Log.info(`${CORE_VIDEO_TAG} core video sdk already loaded`)
          return
        default:
          TVPlatform.reportError({
            type: ErrorType.MEDIA,
            code: CORE_VIDEO_TAG,
            description: 'loadSdk catastrophically failed',
            payload: err,
          })
          this._normalizedPlayerEvents?.publish(new ErrorEvent(err))
          return
      }
    }
  }

  onCoreSessionCreated = (controller: SessionController) => {
    this._sessionController = controller
    if (!this._playerEventEmitterRegistry)
      this._playerEventEmitterRegistry = new PlayerEventEmitterRegistry(
        this._playerMode === PlayerMode.FULL ? PlayerEventSetup.ALL : PlayerEventSetup.MINIMAL
      )
    this._playerEventEmitterRegistry?.attach(controller, this.normalizedPlayerEvents)
  }

  _setCoreConfig() {
    this._coreConfig = {
      enableWorker: false,
    }
  }

  override isPlayingAd(): boolean {
    return (
      (
        this._playerEventEmitterRegistry?.getEmitter(AvailableEmitters.CORE_AD_REPORTER) as
          | CoreVideoAdReporter
          | undefined
      )?.isAdPlaying || false
    )
  }

  override _onEventReceived(event: PlatcoPlayerEvents) {
    super._onEventReceived(event)
    if (event instanceof PlayerStatusEvent) {
      switch (event.status) {
        case PlayerStatus.LOADING:
          if (DebugControllerSingleton.emulateVSFError) {
            TVPlatform.emulateVSFError(this._sessionController)
          }
          break
        case PlayerStatus.PLAYING:
          // We have to enable subtitles each time both session controller
          // and subtitle track are ready
          this._updateSubtitles()
          break
        default:
          break
      }
    } else if (event instanceof SubtitleEvent) {
      const { cues, tracks } = event.subtitleData
      if (cues) {
        this._subtitleCues = cues
      } else if (tracks) {
        this._subtitleTracks = tracks
      }
    }
  }

  _updateSubtitles() {
    this.enableSubtitles(ClosedCaptionsUtils.getCCType())
  }

  async _initializeVideo() {
    if (typeof window !== 'undefined') await this.loadCoreSdk()
    // Creates video player element to hook onto via LightningSDK.
    this._setupVideoTag()
    if (this._playerDomEl) this.setVisibility(true)
  }

  override async _clearSession() {
    super._clearSession()
    this._playerDomEl?.parentNode?.removeChild?.(this._playerDomEl)
    this._playerDomEl = null
    this._coreVideoController?.destroy()
    this._coreVideoController = null
    if (this._sessionController) await this._sessionController.stop()
    this._sessionController = null
    this._playerStatus = PlayerStatus.UNKNOWN
  }

  async load(override?: PlayerData) {
    this._override = override
    try {
      await this._clearSession()

      const { stream, program, lemonade } = this._override || PlayerStoreSingleton

      // Check if we dont get the response from lemonade
      const missingLemonade = !lemonade?.type && (!lemonade?.playbackUrl || !lemonade?.playbackUrls)
      if (missingLemonade || !stream || !program) {
        this._onUnableToRecoverMedia((lemonade as any)?.message)
        return
      }

      await this._initializeVideo()
      // This is important for recovering from media failure since the current time gets wiped.
      // Without this we could get a infinte loop when buffering
      this._lastPosition = 0
      this._startTime =
        ('startTime' in stream && typeof stream.startTime === 'number'
          ? stream.startTime
          : 'startTime' in program && typeof program.startTime === 'number'
          ? program.startTime
          : undefined) ?? -1

      await this.createPlaybackSession()
      this._normalizedPlayerEvents?.publish(
        new LoadSourceEvent(getCoreStreamUrl(lemonade as LemonadeResponse), program)
      )
    } catch (error: any) {
      TVPlatform.reportError({
        type: ErrorType.MEDIA,
        code: CORE_VIDEO_TAG,
        description: 'load error event',
        payload: error,
      })
      // Case when stream is undefined
      const playerError = new PlayerError(true, null, null)
      this._normalizedPlayerEvents?.publish(new ErrorEvent(playerError))
    }
  }

  async createPlaybackSession() {
    const mParticleId = getMpid()
    const mvpd = AuthenticationSingleton.getMvpdData()
    const { mvpdProviderData } = mvpd ?? {}

    const { stream, program, lemonade, geo } = this._override || PlayerStoreSingleton
    if (!lemonade || !stream || !program) return

    const sessionItem = await getSessionConfig({
      lemonade,
      program,
      mvpdHash: mvpdProviderData?.advertisingKey,
      mParticleId,
      mvpd,
      product: getProduct(),
      isNBCProfileLinked: isNBCProfileLinked(),
      stream,
      raw: !!this._override,
      geo,
    })

    Log.info(`${CORE_VIDEO_TAG} session item config:`, sessionItem)

    this._coreVideoController = CoreVideoSdk.getPlayerController({
      videoHtmlContainerInfo: {
        videoHTMLContainer: this._setupVideoTag(),
      },
      disablePlayoutRect: true,
    })

    this._coreVideoController.onSessionCreated(this.onCoreSessionCreated)
    this._coreVideoController.createSession(sessionItem)
  }

  override setCCStyle(options: CCStyleEvent) {
    this._ccDelegate?.setCCStyle(options)
  }

  override play() {
    this._sessionController?.play?.()
  }

  override pause() {
    this._sessionController?.pause?.()
  }

  override seek(positionInMilliseconds: number) {
    if (!this._sessionController) return
    const position = this.getSafeSeekPosition(positionInMilliseconds)
    this._sessionController?.seek?.(positionInMilliseconds)
    Log.info(`${CORE_VIDEO_TAG} seeking to position ${position}`)
  }

  override seekToLiveEdge(): void {
    if (!this._sessionController) return
    this._sessionController?.seekToLiveEdge()
    Log.info(`${CORE_VIDEO_TAG} seeking to live edge`)
  }

  override clearPreviousSession() {
    this._clearSession()
  }

  override close() {
    this._ttsSubscription?.unsubscribe()
    this._ttsSubscription = undefined
    this._detach()
  }

  override isPlaying() {
    const sessionState = this._sessionController?.getCurrentSessionState?.()
    Log.info(`${CORE_VIDEO_TAG} isPlaying - sessionState: ${sessionState}`)
    return sessionState === SessionState.Playing
  }

  enableSubtitles(language: CCTypes) {
    if (language === CCTypes.off) {
      this._sessionController?.disableSubtitles?.()
      return
    }
    let subtitleTrack = this._subtitleTracks?.filter?.((track) => track.language === language)
    // if we can't find a track, check for the fallback language
    if (subtitleTrack?.length === 0) {
      subtitleTrack = this._subtitleTracks?.filter?.(
        (track) => track.language === ClosedCaptionsUtils.getFallbackCCType(language)
      )
    }
    if (subtitleTrack) {
      const { id } = subtitleTrack[0] || {}
      if (id) this._sessionController?.enableSubtitles?.(id)
    }
  }

  _startTts() {
    this.setVolume(0.2)
  }

  _endTts() {
    this.setVolume(1)
  }

  override setVolume(level: number): void {
    if (isXclass()) {
      // The private property is used here because we don't have any other public property to call the player engine element
      // @ts-expect-error TS2341: Property 'engine' is private and only accessible within class 'PlayerController'.
      // rdkaamp uses volume levels of 0-100 instead of 0-1, so we need to adjust volume level when XClass
      this._coreVideoController?.engine?.playerItem?.setVolume(level * 100)
    } else {
      this._sessionController?.setVolume(level)
    }
  }

  get liveLatency() {
    return 0 // Math.round(this._hls?.latency ?? 0)
  }

  get bandwidthEstimate() {
    // in bps
    return 0
  }

  get videoSize() {
    return [0, 0]
  }

  set closedCaptionsEnabled(enabled: any) {
    // FIXME: implement this or remove it altogether
  }

  get closedCaptionLanguages() {
    // TODO: Implement closed captions functionality
    throw new Error('Method not implemented.')
  }

  set closedCaptions(code: any) {
    // TODO: Implement closed captions functionality
    throw new Error('Method not implemented.')
  }

  get audioTracks() {
    // TODO: Implement audioTrack functionality
    throw new Error('Method not implemented.')
  }

  set audioTrack(code) {
    // TODO: Implement audioTrack functionality
    throw new Error('Method not implemented.')
  }

  get audioTrack() {
    // TODO: Implement audioTrack functionality
    throw new Error('Method not implemented.')
  }

  get startPosition() {
    return Math.round(this._startTime)
  }

  get currentPosition() {
    return 0
  }

  get lastPosition() {
    return this._lastPosition
  }

  get seekableRange() {
    return 0
  }

  get duration() {
    return 0
  }

  get view() {
    const videoEls = document.getElementsByTagName('video')
    if (videoEls && videoEls.length) {
      return videoEls[0]
    } else {
      throw new Error('Method not implemented.')
    }
  }
  override get isBuffering() {
    return [SessionState.Rebuffering, SessionState.Seeking].includes(
      this._sessionController?.getCurrentSessionState() as any
    )
  }

  _findVideoElement(): HTMLElement | null | undefined {
    return this._playerDomEl || document.getElementById(this._domId)
  }

  //#region May need to be removed and pass in the html element
  _setupVideoTag = () => {
    const element = this._findVideoElement()
    if (element) return element
    const left = this._options?.left || 0
    const top = this._options?.top || 0
    const width =
      this._options?.width ||
      ((isProduction() || isXbox()) && window.innerWidth ? window.innerWidth : SCREEN_SIZE.width)
    const height = this._options?.height || width / (16 / 9)
    const videoEl = document.createElement('div')
    videoEl.setAttribute('id', this._domId)
    videoEl.style.position = 'absolute'
    videoEl.style.zIndex = String(this._options?.zIndex || 0)
    document.body.appendChild(videoEl)
    this._playerDomEl = videoEl
    this.setVideoSize(left, top, width, height)
    this.setVisibility(false)
    return videoEl
  }

  override setVideoSize(left: number, top: number, width: number, height: number) {
    const videoEl = this._findVideoElement()
    if (!videoEl) return
    const playOutRect = {
      width: TVPlatform.scaleVideoProperty(width, PLAYER_SIZE.FULL),
      height: TVPlatform.scaleVideoProperty(height, PLAYER_SIZE.FULL),
      top: TVPlatform.scaleVideoProperty(top, PLAYER_SIZE.FULL),
      left: TVPlatform.scaleVideoProperty(left, PLAYER_SIZE.FULL),
    }
    videoEl.setAttribute('width', `${playOutRect.width}px`)
    videoEl.setAttribute('height', `${playOutRect.height}px`)
    Object.entries(playOutRect).forEach(([k, v]) => ((videoEl.style as any)[k] = `${v}px`))
    // TODO: Refactor this as a separate delegate
    if (isXclass()) {
      this._coreVideoController?.setPlayoutRect({
        width: playOutRect.width,
        height: playOutRect.height,
        y: playOutRect.top,
        x: playOutRect.left,
      })
    }
  }

  forceVideoSize() {
    const size = [
      this._options?.left,
      this._options?.top,
      this._options?.width,
      this._options?.height,
    ]
    if (isSizeArray(size)) {
      this.setVideoSize(...size)
    }
  }

  override setVisibility(visible: boolean): void {
    const videoEl = this._findVideoElement()
    if (!videoEl) return
    videoEl.style.display = visible ? 'block' : 'none'
    videoEl.style.visibility = visible ? 'visible' : 'hidden'
  }

  override setMute(muted: boolean): void {
    if (isXclass()) {
      // The private property is used here because we don't have any other public property to call the player engine element
      // @ts-expect-error TS2341: Property 'engine' is private and only accessible within class 'PlayerController'.
      this._coreVideoController?.engine?.playerItem?.setMute(muted)
    } else {
      this._sessionController?.setMute(muted)
    }
  }

  override _onError = (error: { fatal?: boolean; [key: string]: any } = { fatal: false }) => {
    if (error.fatal) {
      // Attempt to recover from fatal error.
      if (!this.isRecovering) {
        TVPlatform.reportError({
          type: ErrorType.MEDIA,
          code: CORE_VIDEO_TAG,
          description: 'unknown error, trying to recover media error',
        })
        this._onUnableToRecoverMedia(error)
        return
      }
    }
  }

  _onUnableToRecoverMedia(error: any) {
    TVPlatform.reportError({
      type: ErrorType.MEDIA,
      code: CORE_VIDEO_TAG,
      description: 'unable to recover fatal error, end session',
    })
    this._normalizedPlayerEvents?.publish(new FatalErrorEvent(error))
    this.close()
  }

  override _onRecoverMediaError = () => {
    // Show buffer screen while attempted to recover.
    if (!this.isBuffering) this._state = PlayerState.BUFFERING
    Log.info(`${CORE_VIDEO_TAG} attempt to recover media`)
    // Allow 10s for media to recover.
    this._mediaRecoveryTimeout = window.setTimeout(
      () => {
        this._state = PlayerState.ERROR
      },
      get(
        AppConfigFactorySingleton.config,
        'hls_player.recoverErrorTimeout',
        CoreVideoPlayer.RECOVER_ERROR_TIMEOUT
      )
    )
  }

  setAudioTrack(trackId: number | string): void {
    this._sessionController?.setAudioTrack(trackId)
  }
  // #endregion
}
