import { Language } from '@lightningjs/sdk'
import { ROUTE, ROUTE_ENTITY } from '../../../../constants'
import RouterUtil from '../../../../util/RouterUtil'
import { ACTIVATION_TYPE } from '../constants'
import { ActivationStrategy } from './ActivationStrategy'
import { getUserProfile } from '../../../../api/Identity'
import ModalManager, { CloseReason } from '../../../../lib/ModalManager'

export class CreditsFlowActivationStrategy extends ActivationStrategy {
  override drawText() {
    const title = Language.translate(this._ctx.titleTextKey)
    this._ctx.tag('Title').text.text = title
    return [title]
  }

  override _redirect() {
    if (this._ctx._streamId) {
      const userProfile = getUserProfile()
      const shouldGoToCredits =
        this._activationType === ACTIVATION_TYPE.NBC &&
        !userProfile?.isVideoAlreadyPlayed(this._ctx._streamId)

      if (shouldGoToCredits)
        RouterUtil.navigateToRoute(ROUTE.credits, {
          entity: ROUTE_ENTITY.videoId,
          value: this._ctx._streamId,
        })
      else ModalManager.close(CloseReason.COMPLETED)
    }
  }
}
