import { ACTIVATION_REFERRER } from '../constants'
import { ActivationStrategy } from './ActivationStrategy'
import { EventActivationStrategy } from './EventActivationStrategy'
import { LinearActivationStrategy } from './LinearActivationStrategy'
import { TempPassActivationStrategy } from './TempPassActivationStrategy'
import { VODActivationStrategy } from './VODActivationStrategy'
import { BaseActivation } from '../Activation'
import { VODContentGatingActivationStrategy } from './VODContentGatingActivationStrategy'
import { CreditsFlowActivationStrategy } from './CreditsFlowActivationStrategy'

export const getActivationStrategy = (
  ctx: BaseActivation,
  referrerType: ACTIVATION_REFERRER | undefined
) => {
  switch (referrerType) {
    case ACTIVATION_REFERRER.EVENT:
      return new EventActivationStrategy(ctx)
    case ACTIVATION_REFERRER.LINEAR:
      return new LinearActivationStrategy(ctx)
    case ACTIVATION_REFERRER.VOD:
      return new VODActivationStrategy(ctx)
    case ACTIVATION_REFERRER.TEMPPASS:
      return new TempPassActivationStrategy(ctx)
    case ACTIVATION_REFERRER.CONTENT_GATING:
      return new VODContentGatingActivationStrategy(ctx)
    case ACTIVATION_REFERRER.CREDITS_FLOW:
      return new CreditsFlowActivationStrategy(ctx)
    default:
      return new ActivationStrategy(ctx)
  }
}
