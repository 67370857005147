import { Log, Lightning, Router } from '@lightningjs/sdk'

import PlayerStoreSingleton, { PlayerStoreEvents } from '../../store/PlayerStore/PlayerStore'
import { CoreSDKPlayerFragment, findCoreSDKPlayerComponent, setStreamData } from '../../store/PlayerStore/actions'
import { getLemonade } from '../../store/PlayerStore/actions/lemonade'
import VODCorePlayer from './VODCorePlayer'
import { MPARTICLE_ERROR_CONTEXTS, PlayerStates, ROUTE } from '../../constants'
import AppConfigFactorySingleton from '../../config/AppConfigFactory'
import { RouterPage } from '../../../types/global'
import { Subscription } from 'rxjs'
import { SecondaryPlayerSingleton } from '../../lib/SecondaryPlayer'
import { SubscriptionBuilder, SubscriptionSources } from '../../util/SubscriptionBuilder'
import TVPlatform from '../../lib/tv-platform'
import { ErrorType } from '../../lib/tv-platform/types'
import { openPlayerLoader } from '../../widgets/Modals/playerLoader/PlayerLoader'
import { useRequest } from '../../lib/useRequest'
import { getVodStreamRequestConfig } from '../../helpers'
import { geoCheck } from '../../api/Live'
import { openErrorModal } from '../../widgets/Modals/errorModal/ErrorModal'
import ModalManager from '../../lib/ModalManager'
import AuthenticationSingleton from '../../authentication/Authentication'
import { PlayerError } from '../../components/error/PlayerError'
import { StreamLoaderErrors } from './StreamLoader/error'

const VOD_PLAYER_TAG = 'VOD Player'

export default class VODPlayer extends Lightning.Component<
  Lightning.Component.TemplateSpecLoose,
  RouterPage
> {
  _params: any
  _subscription?: Subscription
  override set params(params: any) {
    this._params = params
  }

  static override _states() {
    return [
      class Ready extends this {
        override _getFocused() {
          return this.tag('PlayerComponent') || this
        }
      },
    ]
  }

  override _init() {
    openPlayerLoader()
  }

  override _active() {
    this._subscription = new SubscriptionBuilder()
      .with(SubscriptionSources.PLAYER_STORE)
      .subscribe(this._onStoreEvent)
    this._fetchStream()
  }

  override _inactive() {
    this._subscription?.unsubscribe()
    this._subscription = undefined
  }

  override _detach() {
    this._clearPlayer()
    ModalManager.close()
  }

  override _onChanged() {
    this._setState('')
    this._clearPlayer()
    this._fetchStream()
  }

  async _fetchStream() {
    await SecondaryPlayerSingleton.destroy()
    const requestConfig = getVodStreamRequestConfig(
      this._params?.isOlympicsVideo,
      this._params?.videoId
    )
    try {
      const data = await useRequest(requestConfig).fetch()
      if (!data) throw data

      const geoCheckResponse = await geoCheck(data?.metadata?.channelId, undefined, {
        contentType: 'vod',
        failOnRestrictionError: false,
      })

      if (geoCheckResponse.restricted) {
        const openErrorModalPayload = {
          error: {
            detail: StreamLoaderErrors.GEO,
            data: geoCheckResponse?.restrictionDetails?.description,
          },
          stack: (new Error()).stack,
        }
        const CoreSDKPlayerComponent = findCoreSDKPlayerComponent(data?.sections) as CoreSDKPlayerFragment
        openErrorModal(
          // @ts-expect-error Property 'authType' is missing in the payload
          openErrorModalPayload,
          CoreSDKPlayerComponent.CoreSDKPlayerData.player,
          CoreSDKPlayerComponent.analytics,
          false
        )
      } else {
        await PlayerStoreSingleton.dispatch(setStreamData(data))
      }
    } catch (error: any) {
      TVPlatform.reportError({
        type: ErrorType.NETWORK,
        code: VOD_PLAYER_TAG,
        description: 'unable to load stream data',
        payload: error,
      })
      Router.navigate(ROUTE.error)
    }
  }

  _clearPlayer() {
    this.patch({
      PlayerComponent: undefined,
    })
  }

  _getPrefetchErrorAnalytics(errorEvent: any, error: any, stream: any) {
    return {
      video: {
        ...stream,
        currentTime: 0,
      },
      errorType: 'Video',
      description: errorEvent.error.data,
      friendlyMessage: error.message.replace('%{brand}', stream.brandDisplayTitle),
      code: errorEvent.error.detail,
      mvpd: AuthenticationSingleton.getMvpdData(),
      source: MPARTICLE_ERROR_CONTEXTS.vod,
      system: 'Other',
      stack: errorEvent.stack,
      fatal: true,
      isLive: false,
    }
  }

  _onStoreEvent = async (event: any) => {
    switch (event.type) {
      case PlayerStoreEvents.STREAM_OK:
        try {
          const { payload } = await PlayerStoreSingleton.dispatch(getLemonade(event.payload.stream))

          if (payload.errors) {
            // Lemonade service not available
            const errorPayload = {
              error: {
                detail: PlayerError.LEMONADE,
                data: payload.errors.message,
              },
              stack: payload.errors.stack,
            }
            openErrorModal(
              // @ts-expect-error Property 'authType' is missing in the payload
              errorPayload,
              event.payload.stream,
              event.payload.program,
              false,
            )
            return
          }

          const res = await fetch(payload.playbackUrl)

          const isCoreVodDisabled = !AppConfigFactorySingleton.config.core_video_sdk.vod_enabled
          const manifest404 = !res.ok // Some assets have a Lemonade entry but aren't reprocessed yet

          if (manifest404 || isCoreVodDisabled) {
            throw new Error(manifest404 ? 'Manifest 404 error' : 'Core VOD is disabled')
          }

          this.patch({
            PlayerComponent: {
              type: VODCorePlayer,
              signals: { restartStream: this._onChanged.bind(this) },
              params: this._params,
            },
          })
        } catch (error) {
          Log.error('Error in _onStoreEvent:', error)
          // If you want to propagate the error further up:
          throw error
        }
        break
      default:
        return
    }
  }

  $onAttached() {
    this._setState(PlayerStates.Ready)
  }

  _updateActiveCues(cues: any) {
    this.tag('PlayerComponent')?._updateActiveCues?.(cues)
  }
}
