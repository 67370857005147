import { Lightning } from '@lightningjs/sdk'
import { Subscription } from 'rxjs'
import { EpgGuide, EpgGuideV2 } from '../../../components'
import { EPG_SLOT_WIDTH, EPG_SLOT_PADDING, PlayerStates, KeyCodes } from '../../../constants'
import { isSleLiveGuideAllowed, isXbox, setSmooth } from '../../../helpers'
import { EpgStateFactory } from '../states/Epg'
import { SubscriptionBuilder, SubscriptionSources } from '../../../util/SubscriptionBuilder'
import { PlayerStoreEvents } from '../../../store/PlayerStore/PlayerStore'
import { SectionsSpawner } from '../../../api/spawners'
import { sendMetric } from '../../../lib/analytics/Analytics'
import { EVENTS } from '../../../lib/analytics/types'
import { getControlsAnalytics } from '../../../components/player/helpers/metadata'
import { PlayerError } from '../../../components/error/PlayerError'
import { inRange } from 'lodash'
import BasePlayer from '../BasePlayer'
import ModalManager, { ModalTypes } from '../../../lib/ModalManager'
import { PlayerStatus } from '../../../player/model/PlayerStatus'

const V1_LIVE_CHANGE = PlayerStoreEvents.EPG_CHANNEL_UPDATED
const V2_EPG_ERROR = PlayerStoreEvents.EPG_ERROR

class HasEpg extends BasePlayer {
  loadEpg(): void {}
  loadStream(): void {}
}

export const WithEpg = <T extends Lightning.Component.Constructor<HasEpg>>(component: T) =>
  class extends component {
    _epgOpened = false
    _epg?: EpgGuideV2 | EpgGuide
    _epgSubscription?: Subscription
    _adsPlaying = false
    override _liveGuideEnabled = true

    static _states() {
      // @ts-expect-error TODO: fix super not working on HOCs
      return [...super._states(), EpgStateFactory(this)]
    }

    override _handleKey(key: KeyboardEvent) {
      // Adding 1 to end because the inRange util doesn't include the end value
      const arrowKeyRange: [start: number, end: number] = isXbox()
        ? [KeyCodes.xbox.Up, KeyCodes.xbox.Right + 1]
        : [KeyCodes.vizio.Left, KeyCodes.vizio.Down + 1]
      if (
        ModalManager._activeModal === ModalTypes.PLAYER_ERROR &&
        inRange(key.keyCode, ...arrowKeyRange)
      ) {
        this.$openLiveGuide(true)
      }
    }

    override _onErrorModalLiveGuide() {
      if (this._player?.status !== PlayerStatus.UNKNOWN && !this._player?.isPlaying())
        this._resetStream()
      this.$openLiveGuide(true)
    }

    override _init() {
      super._init()
      this.patch({
        Epg: {
          type: EpgGuideV2,
          programImageWidth: 380,
          itemHeight: 190,
          itemWidth: EPG_SLOT_WIDTH,
          itemPadding: EPG_SLOT_PADDING,
          visible: false,
        },
      })
      this._epg = this.tag('Epg')
      this._epgSubscription = new SubscriptionBuilder()
        .with(SubscriptionSources.PLAYER_STORE)
        .subscribe(this._onEpgEvent.bind(this))
    }

    override _detach(): void {
      super._detach()
      this._epgSubscription?.unsubscribe()
      this._epgSubscription = undefined
    }

    async _onEpgEvent(payload: any) {
      switch (payload.type) {
        case V2_EPG_ERROR:
          this.setStateAsEpg(true)
          break
        default:
          break
      }
    }

    // TODO Remove this method when live guide V1 won't be supported
    async _onEpgEventV1(payload: any) {
      switch (payload.type) {
        case PlayerStoreEvents.EPG_OK:
          try {
            if (!this._epg) break
            const upcoming = await SectionsSpawner(this.stage, [payload.upcoming])
            this._epg?.sync()
            this._epg?.load()
            this._epg.visible = true
            if (upcoming?.length && isSleLiveGuideAllowed() && 'upcoming' in this._epg)
              this._epg.upcoming = upcoming[0]
            if (this.widgets.loader.visible) setSmooth(this.widgets.loader, 'visible', 0)
            this.loadEpg()
            this._setState(PlayerStates.Epg)
          } catch {
            // no op
          }
          break
        case PlayerStoreEvents.ERROR:
          this._setErrorState(PlayerError.UNKNOWN)
          break
        case V1_LIVE_CHANGE:
          this.loadStream()
          break
      }
    }

    override _onLoaderExit() {
      if (this._epgOpened) {
        this._epgOpened = false // This goes first to avoid infinite loop
        this.$openLiveGuide(true)
      }
    }

    override _onAdStart(event: any) {
      super._onAdStart(event)
      this._adsPlaying = true
    }

    override _onAdEnd() {
      super._onAdEnd()
      this._adsPlaying = false
    }

    async $openLiveGuide(isError?: boolean) {
      this.setStateAsEpg(isError)
      sendMetric(EVENTS.CLICK, {
        name: 'EPG',
        ...getControlsAnalytics(),
      })
    }

    setStateAsEpg(isError?: boolean) {
      this._setState(PlayerStates.Epg, [isError])
    }

    override _hideUI() {
      super._hideUI()
      if (this._epg) this._epg.alpha = 0
    }

    override _disableRouterBack() {
      return this._epgOpened
    }
  }
